import {
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel
} from '@mui/material';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import * as React from 'react';
import dayjs from 'dayjs';
import { getInvoices } from '../../services/report-service';
import AppContext from '../../context';

// dayjs.extend(utc)
const Invoices = () => {
  const appContext = React.useContext(AppContext);
  const [state, setState] = React.useState({
    dates: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
    dateColumn: 'createdAt',
    statuses: [],
    currencies: [],
  })
  const [data, setData] = React.useState(null);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  const convertToCSV = (data) => {
    const csv = data.map((o) => {
      let str = '';
      for (const [key, value] of Object.entries(o)) {
        if (str) {
          str += ',';
        }
        str += (value && value !== 'null') ? '"' + String(value).replace('"', '\"') + '"' : '""';
      }
      return str;
    });

    return csv.join('\r\n');
  }

  const onExportClick = async () => {
    try {
      if (data && data.length > 0) {
        const header = Object.keys(data[0]).join(',');
        var csvContent = header + '\r\n' + convertToCSV(data);
        const BOM = '\uFEFF';
        const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        var link = window.document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        link.click();
      }
    } catch (e) {
      console.log(e);
    }
  }

  const onPreviewClick = async () => {
    try {
      appContext.setLoading(true);
      const result = await getInvoices(state.dates, state.dateColumn, state.statuses, state.currencies);
      setData(result);
    } catch (e) {
      console.log(e);
    } finally {
      appContext.setLoading(false);
    }
  }

  const renderPreview = () => {
    if (data && data.length > 0) {
      const header = Object.keys(data[0]).join(',');
      var csvContent = header + '\r\n' + convertToCSV(data);
      return <pre style={{ overflowX: 'auto', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{csvContent}</pre>;
    }
    return null;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          startText="Start Date"
          endText="End Date"
          value={state.dates}
          onChange={(newValue) => {
            const newDates = [newValue[0].format('YYYY-MM-DD')];
            if (newValue[1]) {
              newDates.push(newValue[1].format('YYYY-MM-DD'));
            }
            setState({ ...state, dates: newDates });
          }}
          inputFormat="YYYY-MM-DD"
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField size="small" {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField size="small" {...endProps} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
      <FormControl sx={{ mt: 3 }}>
        <InputLabel id="date-column-select-label">Date Column</InputLabel>
        <Select
          size="small"
          labelId="date-column-select-label"
          id="dateColumn"
          name="dateColumn"
          value={state.dateColumn}
          label="Date Column"
          onChange={handleChange}
        >
          <MenuItem value="createdAt">Created</MenuItem>
          <MenuItem value="arrivalTime">Arrival</MenuItem>
        </Select>
      </FormControl>
      <br />
      <FormControl sx={{ mt: 3, width: 300 }}>
        <InputLabel id="status-checkbox-label">Statuses</InputLabel>
        <Select
          labelId="status-checkbox-label"
          id="statuses"
          name="statuses"
          size="small"
          label="statuses"
          multiple
          value={state.statuses}
          onChange={handleChange}
          input={<OutlinedInput label="Statuses" />}
          renderValue={(selected) => selected.join(', ')}
        >
          <MenuItem value="new">
            <Checkbox checked={state.statuses.indexOf('new') > -1} />
            <ListItemText primary="New" />
          </MenuItem>
          <MenuItem value="booking">
            <Checkbox checked={state.statuses.indexOf('booking') > -1} />
            <ListItemText primary="Booking" />
          </MenuItem>
          <MenuItem value="invoice">
            <Checkbox checked={state.statuses.indexOf('invoice') > -1} />
            <ListItemText primary="Invoice" />
          </MenuItem>
          <MenuItem value="unpaid">
            <Checkbox checked={state.statuses.indexOf('unpaid') > -1} />
            <ListItemText primary="Unpaid" />
          </MenuItem>
          <MenuItem value="paid">
            <Checkbox checked={state.statuses.indexOf('paid') > -1} />
            <ListItemText primary="Paid" />
          </MenuItem>
          <MenuItem value="operating">
            <Checkbox checked={state.statuses.indexOf('operating') > -1} />
            <ListItemText primary="Operating" />
          </MenuItem>
          <MenuItem value="completed">
            <Checkbox checked={state.statuses.indexOf('completed') > -1} />
            <ListItemText primary="Completed" />
          </MenuItem>
          <MenuItem value="cancelled">
            <Checkbox checked={state.statuses.indexOf('cancelled') > -1} />
            <ListItemText primary="Cancelled" />
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ ml: 2, mt: 3, width: 300 }}>
        <InputLabel id="currency-checkbox-label">Currencies</InputLabel>
        <Select
          labelId="currency-checkbox-label"
          id="currencies"
          name="currencies"
          size="small"
          label="Currencies"
          multiple
          value={state.currencies}
          onChange={handleChange}
          input={<OutlinedInput label="Currencies" />}
          renderValue={(selected) => selected.join(', ')}
        >
          <MenuItem value="RM">
            <Checkbox checked={state.currencies.indexOf('RM') > -1} />
            <ListItemText primary="MYR" />
          </MenuItem>
          <MenuItem value="CNY">
            <Checkbox checked={state.currencies.indexOf('CNY') > -1} />
            <ListItemText primary="CNY" />
          </MenuItem>
        </Select>
      </FormControl>
      <br />
      <Button sx={{ mt: 3, mr: 1 }} onClick={onPreviewClick} size="small" variant="outlined">Preview</Button>
      <Button sx={{ mt: 3 }} onClick={onExportClick} size="small" variant="outlined">Export</Button>
      {data && renderPreview()}
    </Box>
  )
};

export default Invoices;