import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Typography
} from '@mui/material';
const NotFound = () => {

  return (
    <>
      <Helmet>
        <title>小强兰卡威旅程 Langkawi Tour</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          minHeight: "80vh",
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="h1" align="center">
            404
          </Typography>
          <br/><br/>
          <Typography variant="h6" align="center">
            THAT PAGE DOESN'T EXISTS OR IS UNAVAILABLE.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default NotFound;
