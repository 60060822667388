import * as React from 'react';
import {
  Button, TextField, DialogTitle, DialogContent, DialogActions, Dialog, Select, MenuItem, InputLabel, FormControl,
  Box, Card, CardContent, Typography, FormGroup, FormControlLabel, Checkbox, Tabs, Tab, TableContainer, Paper, TableHead, TableRow,
  TableCell, Table, TableBody
} from '@mui/material';
import AppContext from '../../context';
import { decimalsCount } from '../../utils/etc';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { capitalizeText } from '../../utils/etc';
import { uploadSlip_customer } from '../../services/invoice-service';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import sortBy from 'lodash.sortby';

const Payment = ({ invoiceId, passcode, updatePayments, payments, close }) => {
  const appContext = React.useContext(AppContext);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [tab, setTab] = React.useState('transactions');
  // const [transactions, setTransactions] = React.useState(payments);
  const [error, setError] = React.useState({});
  const [state, setState] = React.useState({
    date: dayjs().format('YYYY-MM-DD')
  });

  const validatePayment = () => {
    const maybeError = {
      ...error,
      amount: state.amount === '',
    };
    setError(maybeError);
  };

  const handleInputChange = (e) => {
    let { value } = e.target;
    switch (e.target.name) {
      case 'amount':
        value = value.replace('e', '');
        if (decimalsCount(value) > 2) {
          value = parseFloat(value).toFixed(2);
        }
        break;
      default: break;
    }
    setState({ ...state, [e.target.name]: value });
  };

  const uploadOnChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const submitSlip = async () => {
    validatePayment();
    if ((!state.amount || state.amount === '')) {
      appContext.showMessage('warning', 'Please fill in all the required fields.');
      return;
    }
    if ((!selectedFile)) {
      appContext.showMessage('warning', 'Please select a transfer slip.');
      return;
    }
    try {
      appContext.setLoading(true);
      await uploadSlip_customer(invoiceId, passcode, state.date, selectedFile, state.amount);
      appContext.showMessage('success', 'Payment slip submitted successfully.');
      updatePayments([...payments, { id: uuidv4(), date: state.date, amount: state.amount, status: 'pending' }]);
      setTab('transactions');
    } catch (e) {
      appContext.showMessage('error', 'An error had occurred in submiting transfer slip. Please try again.')
    } finally {
      appContext.setLoading(false);
    }

  };

  return (
    <Dialog sx={{ minHeight: 300 }} fullWidth maxWidth="sm" open onClose={close}>
      <DialogTitle>
        <Typography variant="h4">
          Invoice Payment
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Tabs value={tab} aria-label="tab" onChange={(e, value) => setTab(value)}>
          <Tab label="Transaction History" value="transactions" />
          <Tab label="Make Payment" value="payment" disabled={payments.find((t) => t.status ==='pending')} />
        </Tabs>
        <Card variant="outlined" sx={{ borderColor: '#B5B5B2', borderWidth: 1 }}>
          <CardContent>
            <Box sx={{ minHeight: 300 }}>
              {tab === 'payment' && <>
                <FormGroup>
                  <Box sx={{}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <InputLabel sx={{ paddingLeft: 1, marginTop: 0, fontSize: 12 }} size="small">Transaction Date</InputLabel>
                      <br />
                      <MobileDatePicker
                        size="small"
                        renderInput={(params) => <TextField label="Transaction Date" fullWidth size="small" {...params} />}
                        onChange={(newValue) => {
                          handleInputChange({ target: { name: 'date', value: newValue } });
                        }}
                        value={state.date}
                        inputFormat="YYYY-MM-DD"
                      />
                    </LocalizationProvider>
                    <TextField
                      sx={{ marginTop: 2 }}
                      error={error.amount}
                      helperText={error.amount ? 'Invalid input.' : ''}
                      value={state.amount}
                      fullWidth
                      margin="dense"
                      id="amount"
                      name="amount"
                      label="amount (RM)"
                      size="small"
                      type="number"
                      variant="outlined"
                      onChange={handleInputChange}
                    />
                    <br /><br />
                    Transfer Slip:
                    {' '}
                    {selectedFile && (
                      <span style={{ paddingLeft: 10, paddingRight: 10 }}>
                        {selectedFile.name}
                      </span>
                    )}
                    <Button
                      size="small"
                      component="label"
                    >
                      Select file
                      <input
                        accept="image/*"
                        type="file"
                        hidden
                        onChange={uploadOnChange}
                      />
                    </Button>
                  </Box>

                </FormGroup>
                <br /> <br />
                <Button variant="outlined" onClick={submitSlip}>Submit Payment</Button>
              </>}
              {tab === 'transactions' && <TableContainer component={Paper}>
                <Table aria-label="Payment Table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell align="right">Amount (RM)</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payments.length === 0 && <h4 style={{ paddingLeft: 15}}>No payment Found </h4>}
                    {sortBy(payments, ['createdAt']).map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.date}
                        </TableCell>
                        <TableCell align="right">{parseFloat(row.amount).toFixed(2)}</TableCell>
                        <TableCell>{row.status === 'approved' ? 'Paid' : capitalizeText(row.status)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>}
            </Box>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={() => close(false)}>Close</Button>
        </>
      </DialogActions>
    </Dialog >
  );
};

export default Payment;
