import * as React from 'react';
import {
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  InputLabel,
  Select, MenuItem, Typography,
} from '@mui/material';
import { getUser, createUser, updateUser } from '../../services/user-service';
import AppContext from '../../context';
import { validateEmail } from '../../utils/etc';
import { v4 as uuidv4 } from 'uuid';

const newState = {
  email: '',
  name: '',
  role: 2,
  active: true,
};

const DialogUser = ({ id, close }) => {
  const appContext = React.useContext(AppContext);
  const [error, setError] = React.useState({});
  const [data, setData] = React.useState(newState);

  React.useState(() => {
    const loadData = async () => {
      appContext.setLoading(true);
      try {
        if (id && id !== 'New') {
          const data = await getUser(id);
          setData(data);
        };
      } catch (e) {
        appContext.showMessage('error', `Error in procesing this request: ${e}`);
        console.error(e);
      } finally {
        appContext.setLoading(false);
      }
    };
    loadData();
  }, []);

  const validate = () => {
    const maybeError = {
      ...error,
      name: !data.name || setData.name === '',
      email: !validateEmail(data.email),
    };
    setError(maybeError);
  };

  const handleInputChange = (e) => {
    if (e.target.name === 'active') {
      setData({ ...data, [e.target.name]: e.target.checked });
      return;
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };


  const save = async () => {
    validate();
    if ((!data.email || data.email === '')
      || (!data.name || data.name === '')) {
      appContext.showMessage('warning', 'Please fill in all the required fields.');
      return;
    }
    try {
      if (id === 'New') {
        const created = await createUser(data);
        appContext.showMessage('success', `User ${created.name} created successfully.`);
      } else {
        await updateUser(id, data);
        appContext.showMessage('success', `User ${data.name} updated.`);
      }
      close(true);
    } catch (e) {
      console.error(e);
      appContext.showMessage('error', `Request failed: ${e.response.data}`);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={close}>
      <DialogTitle>
        {`User - ${id}`}
      </DialogTitle>
      <DialogContent>
        <TextField
          value={data.email}
          error={error.email}
          helperText={error.email ? 'Invalid input.' : ''}
          fullWidth
          margin="dense"
          id="email"
          name="email"
          size="small"
          label="Email"
          type="text"
          variant="outlined"
          onChange={handleInputChange}
        />
        <TextField
          value={data.name}
          error={error.name}
          helperText={error.name ? 'Invalid input.' : ''}
          fullWidth
          margin="dense"
          id="name"
          name="name"
          size="small"
          label="Name"
          type="text"
          variant="outlined"
          onChange={handleInputChange}
        />
        <FormControl fullWidth style={{ marginTop: 5 }}>
          <InputLabel id="role-select-label">Role</InputLabel>
          <Select
            value={data.role}
            labelId="role-select-label"
            id="role"
            name="role"
            label="Role"
            size="small"
            onChange={handleInputChange}
          >
            <MenuItem value="0">Account</MenuItem>
            <MenuItem value="1">System Administrator</MenuItem>
            <MenuItem value="2">Internal</MenuItem>
            <MenuItem value="3">External (Operation)</MenuItem>
          </Select>
        </FormControl>
        <FormGroup style={{ paddingTop: 5 }}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={data.active}
                onChange={handleInputChange}
                name="active"
              />
            )}
            label="Active"
          />
        </FormGroup>
        {data.password && <Typography variant="p">New Password: {data.password}</Typography>
        }

      </DialogContent>
      <DialogActions>
        <>
          <Button variant="outlined" onClick={() => setData({ ...data, password: uuidv4().split('-')[0] })}>Reset Password</Button>
          <Button variant="contained" onClick={save}>Save</Button>
          <Button onClick={() => close(false)}>Close</Button>
        </>
      </DialogActions>
    </Dialog>
  );
};


export default DialogUser;
