import { httpGet, httpPut } from '../utils/api';
import configs from '../configs';

export const getCarRentals_external = async (status, search, dateFrom, dateTo, page, limit) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/external/car-rentals?status=${status}&search=${search}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&limit=${limit}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const getCarRental_external = async (id) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/external/car-rentals/${id}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const updateCarRental_external = async (id, car) => {
  const result = await httpPut(
    `${configs.apiBaseUrl}/api/external/car-rentals/${id}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` },
    car
  );
  return result;
};

// To be delete
export const searchCarRentals_internal = async (search, status, page, limit) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/internal/car-rentals?search=${search}&status=${status}&page=${page}&limit=${limit}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};
