import * as React from 'react';
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';

const Summary = ({ total, payment, balance }) => (
  <Box sx={{
    display: 'flex', justifyContent: 'flex-end'
  }}
  >
    <Card>
      <CardContent>
        <Table size="small" sx={{ minWidth: 200 }} aria-label="Summary">
          <TableBody>
            <TableRow sx={{ td: { border: 0 } }}>
              <TableCell>
                Total:
              </TableCell>
              <TableCell align="right">{total}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>
                Payment:
              </TableCell>
              <TableCell align="right">{payment}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Balance: </TableCell>
              <TableCell align="right">{balance}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>

  </Box>
);
export default Summary;
