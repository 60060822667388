import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import theme from './theme';
import getRoutes from './routes';
import './App.css';

const App = () => {
  const content = useRoutes(getRoutes());
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {content}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
