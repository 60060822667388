import {
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel
} from '@mui/material';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import * as React from 'react';
import dayjs from 'dayjs';
import {  getCars } from '../../services/report-service';
import AppContext from '../../context';

const Cars = () => {
  const appContext = React.useContext(AppContext);
  const [state, setState] = React.useState({
    dates: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
    status: 'collected',
  });
  const [data, setData] = React.useState(null);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  const convertToCSV = (data) => {
    const csv = data.map((o) => {
      let str = '';
      for (const [key, value] of Object.entries(o)) {
        if (str) {
          str += ',';
        }
        str += (value && value !== 'null') ? '"' + String(value).replace('"', '\"') + '"' : '""';
      }
      return str;
    });

    return csv.join('\r\n');
  }

  const onExportClick = async () => {
    try {
      appContext.setLoading(true);
      if (data && data.length > 0) {
        const header = Object.keys(data[0]).join(',');
        var csvContent = header + '\r\n' + convertToCSV(data);
        const BOM = '\uFEFF';
        const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        var link = window.document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        link.click();
      }
    } catch (e) {
      console.log(e);
    } finally {
      appContext.setLoading(false);
    }
  }

  const onPreviewClick = async () => {
    try {
      appContext.setLoading(true);
      const result = await getCars(state.dates, state.status);
      setData(result);
    } catch (e) {
      console.log(e);
    } finally {
      appContext.setLoading(false);
    }
  }

  const renderPreview = () => {
    if (data && data.length > 0) {
      const header = Object.keys(data[0]).join(',');
      var csvContent = header + '\r\n' + convertToCSV(data);
      return <pre style={{ overflowX: 'auto', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{csvContent}</pre>;
    }
    return null;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          startText="Start Date"
          endText="End Date"
          value={state.dates}
          onChange={(newValue) => {
            const newDates = [newValue[0].format('YYYY-MM-DD')];
            if (newValue[1]) {
              newDates.push(newValue[1].format('YYYY-MM-DD'));
            }
            setState({ ...state, dates: newDates });
          }}
          inputFormat="YYYY-MM-DD"
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField size="small" {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField size="small" {...endProps} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
      <FormControl sx={{ mt: 3 }}>
        <InputLabel id="date-column-select-label">Status</InputLabel>
        <Select
          size="small"
          labelId="date-column-select-label"
          id="status"
          name="status"
          value={state.status}
          label="Status"
          onChange={handleChange}
        >
          <MenuItem value="collected">Picked Up</MenuItem>
          <MenuItem value="returned">Returned</MenuItem>
        </Select>
      </FormControl>
      <br />
      <br />
      <Button sx={{ mt: 3, mr: 1 }} onClick={onPreviewClick} size="small" variant="outlined">Preview</Button>
      <Button sx={{ mt: 3 }} onClick={onExportClick} size="small" variant="outlined">Export</Button>
      {data && renderPreview()}
    </Box>
  )
};

export default Cars;