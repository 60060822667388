import dayjs from 'dayjs';
import { capitalizeText, computeSummary } from './etc';
import { PaymentTypes, Sources } from '../configs/system';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);

const printHeader = `
  <div class="flex-container">
    <div>
      <img width="65px" src='/static/images/logo.png' />
    </div>
    <div class="company-header">
      XIAO QIANG HOLIDAYS SDN.BHD (Company No.1047644-K) KPK/LN: 7371 <br/>
      <p class="company-contact">
        26 & 28 1st Floor, Jalan Pandak Mayah 4, Mukim Kuah 07000 Langkawi Kedah, Mukim Kuah, 07000 Langkawi, Kedah Darul Aman, Malaysia. <br/>
        Tel: +604-9665576 <br/>
        Web: https://eticket.xqholidays.com.my
      </p>
    </div>
  </div>
`;

const printDetail = (invoice) => `
<div>
  <table class="full-width">
    <tr>
      <td class="table-header">Name</td>
      <td class="table-content">${invoice.name}</td>
      <td class="table-header">Contact</td>
      <td class="table-content">${invoice.contact}</td>
    </tr>
    <tr>
      <td class="table-header">Email</td>
      <td class="table-content">${invoice.email}</td>
      <td class="table-header">Nationality</td>
      <td class="table-content">${invoice.nationality}</td>
    </tr>
    <tr>
      <td class="table-header">ETA</td>
      <td class="table-content">${invoice.arrivalTimeConfirmed ? dayjs.utc(invoice.arrivalTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm') : 'TBC'}</td>
      <td class="table-header">ETD</td>
      <td class="table-content">${invoice.departureTimeConfirmed ? dayjs.utc(invoice.departureTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm') : 'TBC'}</td>
    </tr>
    <tr>
      <td class="table-header">Location</td>
      <td class="table-content">${capitalizeText(invoice.pickUp)}</td>
      <td class="table-header">Location</td>
      <td class="table-content">${capitalizeText(invoice.dropOff)}</td>
    </tr>
    <tr>
      <td class="table-header">Due Date</td>
      <td class="table-content">${dayjs.utc(invoice.dueDate).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD')}</td>
      <td class="table-header">Source</td>
      <td class="table-content">${Sources.find((s) => s.id === invoice.source).name}</td>
    </tr>
    <tr>
      <td class="table-header">Car</td>
      <td class="table-content">${invoice.carNumbers ? invoice.carNumbers : 'N/A'}</td>
      <td class="table-header">XQ Car</td>
      <td class="table-content">${invoice.xqCars ? invoice.xqCars : 'N/A'}</td>
    </tr>
    <tr>
      <td class="table-header">Guests</td>
      <td colspan="3" class="table-content">${invoice.adults} adult(s), ${invoice.children} children, ${invoice.infants} infant(s)</td>
      <td />
      <td />
    </tr>
    <tr>
      <td class="table-header" style="vertical-align:top">Remark</td>
      <td colspan="3" class="table-content" style="overflow: auto; white-space: pre-wrap; word-wrap:break-word">${invoice.remark}</td>
    </tr>
    <tr>
      <td class="table-header" style="vertical-align:top">Notes</td>
      <td colspan="3" class="table-content" style="overflow: auto; white-space: pre-wrap; word-wrap:break-word">${invoice.notes}</td>
    </tr>
  </table>
</div>
`;

const printItems = (items) => {
  const renderItemsHTML = items.filter((i) => !i.deleted).map((i) => `
    <tr style="border-bottom: 1px solid #DFDEDE;">
      <td class="table-cell-padding">${i.booked ? 'Booked' : ''}</td>
      <td class="table-cell-padding">${i.itemId}</td>
      <td class="table-cell-padding" style="max-width: 200px; overflow: auto; white-space: pre-wrap; word-wrap:break-word">${i.desc}</td>
      <td class="table-cell-padding">${i.billNo}</td>
      <td class="table-cell-padding" style="text-align: right">${parseFloat(i.cost).toFixed(2)}</td>
      <td class="table-cell-padding" style="text-align: right">${parseFloat(i.price).toFixed(2)}</td>
      <td class="table-cell-padding" style="text-align: right">${i.quantity}</td>
      <td class="table-cell-padding" style="text-align: right">${parseFloat(i.price * i.quantity).toFixed(2)}</td>
    </tr>`);
  return `
    <div>
      <table class="full-width" style="border-collapse: collapse; padding: 10px; border-style: solid">
        <tr style="border-bottom: 1px solid">
          <th class="table-cell-padding" style="text-align: left">Status</th>
          <th class="table-cell-padding" style="text-align: left">Item</th>
          <th class="table-cell-padding" style="text-align: left">Description</th>
          <th class="table-cell-padding" style="text-align: left">Bill No.</th>
          <th class="table-cell-padding" style="text-align: right">Cost</th>
          <th class="table-cell-padding" style="text-align: right">Price</th>
          <th class="table-cell-padding" style="text-align: right">Qty</th>
          <th class="table-cell-padding" style="text-align: right">Amount (RM)</th>
        </tr>
        ${renderItemsHTML.join(' ')}
      </table>
    </div>
  `;
}

const printItemReservationVouchers = (vouchers, items) => {
  const renderItemsHTML = vouchers.map((i) => {
    console.log(items, 'items');
    console.log(i);
    const item = items.find((item) => item.id === i.invoiceItemId);
    return `<tr style="border-bottom: 1px solid #DFDEDE;">
      <td class="table-cell-padding">${item.itemId} | ${item.billNo}</td>
      <td class="table-cell-padding">${capitalizeText(i.paymentType)}</td>
      <td class="table-cell-padding">${dayjs(i.paymentDate).format('YYYY-MM-DD')}</td>
      <td class="table-cell-padding" style="text-align: right">${parseFloat(i.amount).toFixed(2)}</td>
      <td class="table-cell-padding" style="max-width: 200px; overflow: auto; white-space: pre-wrap; word-wrap:break-word">${i.desc ? i.desc : ''}</td>
    </tr>`});
  return `
    <div>
      <table class="full-width" style="border-collapse: collapse; padding: 10px; border-style: solid">
        <tr style="border-bottom: 1px solid">
          <th class="table-cell-padding" style="text-align: left">Reservation Voucher</th>
          <th class="table-cell-padding" style="text-align: left">Payment Type</th>
          <th class="table-cell-padding" style="text-align: left">Payment Date</th>
          <th class="table-cell-padding" style="text-align: right">Amount (RM)</th>
          <th class="table-cell-padding" style="text-align: left">Remark</th>
        </tr>
        ${renderItemsHTML.join(' ')}
      </table>
    </div>
  `;
}

const printPayments = (payments, users) => {
  const renderPaymentsHTML = payments.map((i) => `
    <tr style="border-bottom: 1px solid #DFDEDE;">
      <td class="table-cell-padding">${capitalizeText(i.payment)}</td>
      <td class="table-cell-padding">${dayjs(i.date).format('YYYY-MM-DD')}</td>
      <td class="table-cell-padding">${PaymentTypes.find((t) => t.id === i.type).name}</td>
      <td class="table-cell-padding" style="text-align: right">${parseFloat(i.amount).toFixed(2)}</td>
      <td class="table-cell-padding" style="max-width: 200px">${i.remark ? i.remark : 'N/A'}</td>
      <td class="table-cell-padding">${capitalizeText(i.status)}</td>
      <td class="table-cell-padding">${users.find((u) => u.id === i.approver) ? users.find((u) => u.id === i.approver).name : ''}</td>
    </tr>`);
  return `
    <div>
      <table class="full-width" style="border-collapse: collapse; padding: 10px; border-style: solid">
        <tr style="border-bottom: 1px solid">
          <th class="table-cell-padding" style="text-align: left">Payment</th>
          <th class="table-cell-padding" style="text-align: left">Date</th>
          <th class="table-cell-padding" style="text-align: left">Type</th>
          <th class="table-cell-padding" style="text-align: right">Amount (RM)</th>
          <th class="table-cell-padding" style="text-align: left">Remark</th>
          <th class="table-cell-padding" style="text-align: left">Status</th>
          <th class="table-cell-padding" style="text-align: left">Approver</th>
        </tr>
        ${renderPaymentsHTML.join(' ')}
      </table>
    </div>
  `;
}

export const print = (invoice, items, reservationVouchers, payments, users) => {
  const summary = computeSummary(items, payments, invoice.currencyRate);
  const html = `
  <!DOCTYPE html>
  <html>
     <head>
        <style>
           .flex-container {
            display: flex;
           }
           .flex-container > div {
            margin: 10px;
            padding: 10px 5px;
           }
           .company-header {
            font-size: 16px;
            font-weight: bold;
           }
           .company-contact {
            font-size: 12px;
            font-weight: normal;
           }
           .full-width {
             width: 100%;
           }
           .table-header {
              font-weight: bold;
              width: 10%;
           }
           .table-content {
              width: 40%;
              padding-right: 5px;
           }
           .table-cell-padding {
             vertical-align: top;
             padding: 10px 10px;
           }
        </style>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
          $(document).ready(function(){
            setTimeout(function(){ window.print(); }, 500);
          });
        </script>
     </head>
     <body>
        ${printHeader}
        <h4 style="padding: 0px 5px">Invoice ${invoice.id}</h4>
        ${printDetail(invoice)}
        <br/>
        ${printItems(items)}
        <br/>
        ${printItemReservationVouchers(reservationVouchers, items)}
        <br/>
        ${printPayments(payments, users)}
        <br/>
        <table class="full-width" style="border-collapse: collapse; padding: 10px 20px">
          <tr>
            <td>
            </td>
            <th style="text-align: left; width: 120px">Total (RM):
            </th>
            <td style="width: 80px; text-align: right;">${summary.total}
            </td>
          </tr>
          <tr>
            <td>
            </td>
            <th style="text-align: left; width: 120px">Paid (RM):
            </th>
            <td style="width: 80px; text-align: right;">${summary.payment}
            </td>
          </tr>
          <tr>
            <td>
            </td>
            <th style="border-top: 1px solid; text-align: left; width: 120px">
            </th>
            <td style="border-top: 1px solid;  width: 80px; text-align: right;"></td>
          </tr>
          <tr>
            <td>
            </td>
            <th style="text-align: left; width: 120px">Balance (RM):
            </th>
            <td style="width: 80px; text-align: right;">${summary.balance}
            </td>
          </tr>
          ${invoice.currency !== 'RM'
            ? `<tr>
                <td>
                </td>
                <th style="text-align: left; width: 120px">Balance (${invoice.currency}):
                </th>
                <td style="width: 80px; text-align: right;">${summary.balanceWithRate}
                </td>
              </tr>`
            : ``}
          <tr>
            <td>
            </td>
            <th style="border-bottom: 1px solid; text-align: left; width: 120px">
            </th>
            <td style="border-bottom: 1px solid;  width: 80px; text-align: right;"></td>
          </tr>
        </table>
        
     </body>
     </html>
  `;
  return html;
};