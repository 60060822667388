import * as React from 'react';
import {
  Button, IconButton, TextField, DialogTitle, DialogContent, DialogActions, Dialog, Select, MenuItem, InputLabel, FormControl,
  Grid, Box, Card, CardContent, Typography, AppBar, Toolbar, Checkbox, FormControlLabel
} from '@mui/material';
import AppContext from '../../context';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import { getInvoiceLogs } from '../../services/invoice-service';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);

const Logs = ({ id, close }) => {
  const appContext = React.useContext(AppContext);
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState({
    rows: [],
    count: 0
  },);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const loadLogs = async () => {
    try {
      appContext.setLoading(true);
      const result = await getInvoiceLogs(id, page, 10);
      setData(result);
    } catch (e) {
      console.error(e);
      appContext.showMessage('error', `Error in procesing this request: ${e}`);
    } finally {
      appContext.setLoading(false);
    }
  };

  React.useState(() => {
    loadLogs();
  }, []);

  return (
    <Dialog fullWidth maxWidth="lg" open onClose={close}  >
      <DialogTitle>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h4" component="div">
              {`Invoice - ${id} Logs`}
            </Typography>
            <Button autoFocus onClick={() => close(true)} color="inherit">
              Close
            </Button>
          </Toolbar>
        </AppBar>

      </DialogTitle>
      <DialogContent style={{ height: '80vh', margin: 15 }}>

        <div>
          {data.rows.map(r => {
            const invoice = r.invoice.invoice;
            return (<Accordion key={r.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  {appContext.options.users.find(u => u.id === r.createdBy).name} at {dayjs.utc(r.createdAt).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">
                  <Grid container>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Name:</b> {invoice.name}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Contact:</b> {invoice.contact}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Email:</b> {invoice.email}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Due Date:</b> {invoice.dueDate}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Arrival Time:</b> {dayjs.utc(invoice.arrivalTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm')}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Confirmed Arrival Time:</b> {invoice.arrivalTimeConfirmed ? 'Yes' : 'No'}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Departure Time:</b> {dayjs.utc(invoice.departureTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm')}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Confirmed Departure Time:</b> {invoice.departureTimeConfirmed ? 'Yes' : 'No'}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Pick Up:</b> {invoice.pickUp}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Drop Off:</b> {invoice.dropOff}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Source:</b> {invoice.source}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Nationality:</b> {invoice.nationality}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Adult:</b> {invoice.adults}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Children:</b> {invoice.children}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Infant:</b> {invoice.infants}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Car Numbers:</b> {invoice.carNumbers}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>XQ Cars:</b> {invoice.xqCars}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Status:</b> {invoice.status}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Assignee:</b> {appContext.options.users.find(u => u.id === invoice.assignee).name}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Person In Charge:</b> {invoice.pic}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Currency:</b> {invoice.currency}
                    </Grid>
                    <Grid xs={12} md={2} lg={3}>
                      <b>Currency Rate:</b> {invoice.currencyRate}
                    </Grid>
                    <Grid xs={12} md={12} lg={12}>
                      <b>Remark:</b> {invoice.remark}
                    </Grid>
                    <Grid xs={12} md={12} lg={12}>
                      <b>Internal Notes:</b> {invoice.notes}
                    </Grid>
                  </Grid>
                  <h4>Items</h4>
                  <table style={{ width: '100%', border: '1px solid', padding: '10px 20px'}}>
                    <tr>
                      <th style={{ textAlign: 'left'}}>Booked?</th>
                      <th style={{ textAlign: 'left'}}>ItemId</th>
                      <th style={{ textAlign: 'left'}}>BillNo</th>
                      <th style={{ textAlign: 'left'}}>Desc</th>
                      <th style={{ textAlign: 'right'}}>Cost</th>
                      <th style={{ textAlign: 'right'}}>Price</th>
                      <th style={{ textAlign: 'right'}}>Quantity</th>
                      <th style={{ textAlign: 'left'}}>Due Date</th>
                    </tr>
                    {r.invoice.items.map(i => (
                      <tr key={i.id}>
                        <td style={{ textAlign: 'left'}}>{i.booked ? 'Yes' : 'No'}</td>
                        <td style={{ textAlign: 'left'}}>{i.itemId}</td>
                        <td style={{ textAlign: 'left'}}>{i.billNo}</td>
                        <td style={{ textAlign: 'left'}}>{i.desc}</td>
                        <td style={{ textAlign: 'right'}}>{i.cost}</td>
                        <td style={{ textAlign: 'right'}}>{i.price}</td>
                        <td style={{ textAlign: 'right'}}>{i.quantity}</td>
                        <td style={{ textAlign: 'left'}}>{i.dueDate ? i.dueDate : ''}</td>
                      </tr>
                    ))}
                  </table>
                  <h4>Payments</h4>
                  <table style={{ width: '100%', border: '1px solid', padding: '10px 20px'}}>
                    <tr>
                      <th style={{ textAlign: 'left'}}>Date</th>
                      <th style={{ textAlign: 'left'}}>Type</th>
                      <th style={{ textAlign: 'left'}}>Payment</th>
                      <th style={{ textAlign: 'right'}}>Amount</th>
                      <th style={{ textAlign: 'left'}}>Remark</th>
                      <th style={{ textAlign: 'left'}}>Status</th>
                      <th style={{ textAlign: 'left'}}>Approver</th>
                    </tr>
                    {r.invoice.payments.map(i => (
                      <tr key={i.id}>
                        <td style={{ textAlign: 'left'}}>{i.date}</td>
                        <td style={{ textAlign: 'left'}}>{i.type}</td>
                        <td style={{ textAlign: 'left'}}>{i.payment}</td>
                        <td style={{ textAlign: 'right'}}>{i.amount}</td>
                        <td style={{ textAlign: 'left'}}>{i.remark}</td>
                        <td style={{ textAlign: 'left'}}>{i.status}</td>
                        <td style={{ textAlign: 'left'}}>{i.approverName}</td>
                      </tr>
                    ))}
                  </table>
                  <h4>Reservation Vouchers</h4>
                  <table style={{ width: '100%', border: '1px solid', padding: '10px 20px'}}>
                    <tr>
                      <th style={{ textAlign: 'left'}}>Item Id</th>
                      <th style={{ textAlign: 'left'}}>Payment Type</th>
                      <th style={{ textAlign: 'left'}}>Payment Date</th>
                      <th style={{ textAlign: 'right'}}>Amount</th>
                      <th style={{ textAlign: 'left'}}>Remark</th>
                    </tr>
                    {r.invoice.reservationVouchers.map(i => (
                      <tr key={i.id}>
                        <td style={{ textAlign: 'left'}}>{i.invoiceItemId}</td>
                        <td style={{ textAlign: 'left'}}>{i.paymentType}</td>
                        <td style={{ textAlign: 'left'}}>{i.paymentDate}</td>
                        <td style={{ textAlign: 'right'}}>{i.amount}</td>
                        <td style={{ textAlign: 'left'}}>{i.remark}</td>
                      </tr>
                    ))}
                  </table>
                </Typography>
              </AccordionDetails>
            </Accordion>);
          })}
        </div>


      </DialogContent>
    </Dialog >
  );
};

export default Logs;
