import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Drawer, Hidden, List, ListItem, Button } from '@mui/material';
import {
  BarChart as BarChartIcon,
  User as UserIcon,
  List as ListIcon,
  Users as UsersIcon,
  FileText as FileTextIcon,
  ShoppingBag as ProductIcon,
  LogOut as LogOutIcon,
  Activity as ActivityIcon,
  Bell as BellIcon,
} from 'react-feather';
import { getLoginSession, logout } from '../services/auth-service';
import NavItem from './NavItem';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
    roles: [1]
  },
  {
    href: '/app/invoices',
    icon: FileTextIcon,
    title: 'Invoices',
    roles: [0, 1, 2]
  },
  {
    href: '/app/reservation-vouchers',
    icon: ConfirmationNumberOutlinedIcon,
    title: 'Reservation Vouchers',
    roles: [0, 1, 2]
  },
  {
    href: '/app/reports',
    icon: ActivityIcon,
    title: 'Reports',
    roles: [0, 1]
  },
  {
    href: '/app/items',
    icon: ProductIcon,
    title: 'Items',
    roles: [0, 1]
  },
  {
    href: '/app/users',
    icon: UsersIcon,
    title: 'Users',
    roles: [1]
  },
  {
    href: '/app/reminders',
    icon: BellIcon,
    title: 'Reminders',
    roles: [1, 2, 3]
  },
  {
    href: '/app/tasks',
    icon: ListIcon,
    title: 'Tasks',
    roles: [1, 3]
  },
  {
    href: '/app/car-rentals',
    icon: DirectionsCarFilledOutlinedIcon,
    title: 'Pick Up / Return',
    roles: [1, 3]
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account',
    roles: [0, 1, 2, 3]
  },
];

const SideBar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const loginSession = getLoginSession();
  const filteredItems = loginSession ? items.filter((i) => i.roles.includes(loginSession.role)) : [];

  const navigate = useNavigate();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const onLogoutClick = () => {
    logout();
    navigate('/login', { replace: true });
    window.location.reload(true);
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box sx={{ p: 2 }}>
        <List>
          {filteredItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
          <ListItem
            disableGutters
            sx={{
              display: 'flex',
              py: 0
            }}
          >
            <Button
              onClick={onLogoutClick}
              sx={{
                color: 'text.secondary',
                fontWeight: 'medium',
                justifyContent: 'flex-start',
                letterSpacing: 0,
                py: 1.25,
                textTransform: 'none',
                width: '100%',
                '& svg': {
                  mr: 1
                }
              }}>
              <LogOutIcon size="20" />
              <span>
                Logout
              </span>
            </Button>
          </ListItem>
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default SideBar;
