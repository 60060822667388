import dayjs from 'dayjs';

export const validateContactNumber = (phone) => {
  const regex = /^(\d+-?)+\d+$/;
  return regex.test(phone);
};
export const validateEmail = (email) => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(email);
};
export const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
export const dateFormat = 'YYYY-MM-DD';
export const decimalsCount = (value) => {
  if ((value % 1) != 0) { return value.toString().split('.')[1].length; }
  return 0;
};
export const capitalizeText = (text) => {
  if (text) return text.charAt(0).toUpperCase() + text.slice(1);
  return '';
};
export const computeSummary = (items, payments, rate) => {
  const sum = (accumulator, a) => accumulator + parseFloat(a);
  const total = parseFloat(items.filter((i) => !i.deleted).map((i) => i.price * i.quantity).reduce(sum, 0)).toFixed(2);
  const payment = parseFloat(payments
    .filter((p) => p.status === 'approved')
    .map((p) => (p.type === 'revert' ? (-1 * p.amount ) : p.amount))
    .reduce(sum, 0))
    .toFixed(2);
  const balance = parseFloat(total - payment).toFixed(2);
  const balanceWithRate = (balance * rate).toFixed(2);
  return {
    total,
    payment,
    balance,
    balanceWithRate
  }
}

export const getShortMonthName = (month) => {
  let desc;
  switch (month) {
    case 0: desc = 'Jan';
      break;
    case 1: desc = 'Feb';
      break;
    case 2: desc = 'Mar';
      break;
    case 3: desc = 'Apr';
      break;
    case 4: desc = 'May';
      break;
    case 5: desc = 'Jun';
      break;
    case 6: desc = 'Jul';
      break;
    case 7: desc = 'Aug';
      break;
    case 8: desc = 'Sep';
      break;
    case 9: desc = 'Oct';
      break;
    case 10: desc = 'Nov';
      break;
    case 11: desc = 'Dec';
      break;
    default:
  }
  return desc;
};

export const getDashboardYears = () => {
  const currentYear = dayjs().get('year');
  const years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4, currentYear - 5,
    currentYear - 6, currentYear - 7, currentYear - 8, currentYear - 9
  ];
  return years.filter((y) => y >= 2021);
}

export const formatNumberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}