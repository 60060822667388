import { useState } from 'react';
import configs from '../configs';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { Search as SearchIcon } from 'react-feather';
const GridView = ({ search, columns, data, SelectedDialog, allowAdd, selectedAction, selectedRowIdColumn }) => {
  const [page, setPage] = useState(0)
  const [searchText, setSearchText] = useState('');
  const [selectedId, setSelectedId] = useState(null);

  const close = (refreshGrid) => {
    setSelectedId(null);
    if (refreshGrid) {
      search(searchText, page);
    }
  }

  const onPageChanged = (e) => {
    setPage(e);
    search(searchText, e);
  }

  return (
    <Box sx={{ mt: 1 }}>
      {(SelectedDialog && selectedId) && <SelectedDialog id={selectedId} close={close} />}
      <Grid container spacing={1}>
        <Grid item lg={6} md={6} sm={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <TextField
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search..."
              variant="outlined"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Button
              onClick={() => search(searchText, 0)}
              style={{ marginLeft: 10 }}
              variant="outlined"
              size="small"
            >
              Search
            </Button>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          {allowAdd &&
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => setSelectedId('New')}
              >
                New
              </Button>
            </Box>
          }
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <Box sx={{
            '& .MuiDataGrid-cell': {
              cursor: 'pointer'
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none'
            },
            height: 650, width: '100%'
          }}>
            <DataGrid
              paginationMode="server"
              hideFooterSelectedRowCount
              disableColumnMenu
              rows={data.rows}
              columns={columns}
              pageSize={configs.pageSize}
              rowCount={data.count}
              onRowClick={async (e) => {
                setSelectedId(selectedRowIdColumn ? e.row[selectedRowIdColumn] : e.id);
                if (selectedAction) {
                  await selectedAction(selectedRowIdColumn ? e.row[selectedRowIdColumn] : e.id);
                }
              }}
              onPageChange={onPageChanged}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GridView;
