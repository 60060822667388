import axios from 'axios';

export const httpPost = async (url, headers, params) => {
  try {
    const result = await axios.post(url, params, { headers });
    if (result.status >= 300) {
      throw new Error(`Http request response with error code ${result.status}`);
    }
    return result.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const httpPut = async (url, headers, params) => {
  try {
    const result = await axios.put(url, params, { headers });
    if (result.status >= 300) {
      throw new Error(`Http request response with error code ${result.status}`);
    }
    return result.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const httpGet = async (url, headers, params) => {
  try {
    const result = await axios.get(url, { headers, params });
    if (result.status >= 300) {
      throw new Error(`Http request response with error code ${result.status}`);
    }
    return result.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};