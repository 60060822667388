import * as React from 'react';
import {
  Button, TextField, DialogTitle, DialogContent, DialogActions, Dialog, InputLabel, FormControl,
  Box, Card, CardContent, Typography, FormGroup, FormControlLabel, Checkbox, Autocomplete
} from '@mui/material';
import AppContext from '../../context';
import NumberInput from '../NumberInput';
import { decimalsCount } from '../../utils/etc';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const InvoiceItem = ({ item, close, updateItem }) => {
  const appContext = React.useContext(AppContext);
  const [error, setError] = React.useState({});
  const [state, setState] = React.useState(item);

  const validateInvoiceItem = () => {
    const maybeError = {
      ...error,
      itemId: !state.itemId,
      cost: state.cost === '',
      price: state.price === '',
    };
    setError(maybeError);
  };

  const handleInputChange = (e) => {
    if (e.target.name === 'booked') {
      setState({ ...state, [e.target.name]: e.target.checked });
      return;
    }

    if (e.target.name === 'itemId') {
      const itemInfo = appContext.options.items.find((i) => i.id === e.target.value);
      if (itemInfo) {
        setState({ ...state, [e.target.name]: e.target.value, desc: itemInfo.name, cost: parseFloat(itemInfo.cost).toFixed(2), price: parseFloat(itemInfo.price).toFixed(2), dueDate: state.dueDate });
      } else {
        setState({ ...state, [e.target.name]: e.target.value, desc: '', cost: parseFloat(0).toFixed(2), price: parseFloat(0).toFixed(2), dueDate: state.dueDate });
      }
      return;
    }

    let { value } = e.target;
    switch (e.target.name) {
      case 'cost':
      case 'price':
        value = value.replace('e', '');
        if (decimalsCount(value) > 2) {
          value = parseFloat(value).toFixed(2);
        }

        break;
      default: break;
    }
    setState({ ...state, [e.target.name]: value });
  };

  const save = async () => {
    validateInvoiceItem();
    if ((!state.itemId)
      || (state.cost === '') || (state.price === '')) {
      appContext.showMessage('warning', 'Please fill in all the required fields.');
      return;
    }

    updateItem(state);
    close(false);
  };

  const handleQuantityChanged = (type) => {
    switch (type) {
      case '+':
        setState({ ...state, quantity: state.quantity + 1 })
        break;
      case '-':
        if (state.quantity === 1) {
          return;
        }
        setState({ ...state, quantity: state.quantity - 1 })
        break;
      default:
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={close}>
      <DialogTitle>
        <Typography variant="h4">
          Invoice Item
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Card variant="outlined" sx={{ borderColor: '#B5B5B2', borderWidth: 1 }}>
          <CardContent>
            <FormGroup>
              <FormControlLabel control={
                <Checkbox id="booked"
                  onChange={handleInputChange}
                  name="booked"
                  checked={state.booked}
                />
              } label="Is this item booked?" />
              <FormControl fullWidth>
                <Autocomplete
                  disabled={!item.isNew}
                  id="itemId"
                  value={appContext.options.items.find((i) => i.id === state.itemId)}
                  onChange={(e, value) => handleInputChange({ target: { name: 'itemId', value: value ? value.id : null } })}
                  options={appContext.options.items.filter(i => i.active == 1)}
                  getOptionLabel={(option) => `${option.id} - ${option.name}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Item"
                      placeholder="Item"
                    />
                  )}
                />
                {error.itemId && (
                  <span style={{
                    color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                  }}
                  >
                    Invalid input.
                  </span>
                )}
                <TextField
                  sx={{ marginTop: 2 }}
                  value={state.desc}
                  fullWidth
                  id="desc"
                  name="desc"
                  label="Description"
                  multiline
                  rows={2}
                  inputProps={{ maxLength: 360 }}
                  onChange={handleInputChange}
                />
                <TextField
                  value={state.billNo}
                  fullWidth
                  margin="dense"
                  id="billNo"
                  name="billNo"
                  label="Bill No."
                  size="small"
                  type="text"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl sx={{ paddingBottom: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel sx={{ marginTop: -1, fontSize: 12 }} size="small">RV Due Date</InputLabel>
                  <br />
                  <MobileDatePicker
                    size="small"
                    renderInput={(params) => <TextField label="RV Due Date" fullWidth size="small" {...params} />}
                    onChange={(newValue) => {
                      handleInputChange({ target: { name: 'dueDate', value: newValue } });
                    }}
                    value={state.dueDate}
                    inputFormat="YYYY-MM-DD"
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  error={error.cost}
                  helperText={error.cost ? 'Invalid input.' : ''}
                  value={state.cost}
                  fullWidth
                  margin="dense"
                  id="cost"
                  name="cost"
                  label="Cost (RM)"
                  size="small"
                  type="number"
                  variant="outlined"
                  onChange={handleInputChange}
                />
                <TextField
                  error={error.price}
                  helperText={error.price ? 'Invalid input.' : ''}
                  value={state.price}
                  fullWidth
                  margin="dense"
                  id="price"
                  name="price"
                  label="Price (RM)"
                  size="small"
                  type="number"
                  variant="outlined"
                  onChange={handleInputChange}
                />

              </FormControl>
              <InputLabel sx={{ marginTop: 1, paddingLeft: 1, fontSize: 12 }}>Quantity</InputLabel>
              <Box sx={{ display: 'block', paddingLeft: 2, paddingTop: 0, paddingBottom: 2, border: 'solid 1px #B5B5B2', borderRadius: 1 }}>
                <NumberInput title="" value={state.quantity} increase={() => handleQuantityChanged('+')} decrease={() => handleQuantityChanged('-')} />
              </Box>
            </FormGroup>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <>
          <Button variant="contained" onClick={save}>Save</Button>
          <Button onClick={() => close(false)}>Close</Button>
        </>
      </DialogActions>
    </Dialog >
  );
};

export default InvoiceItem;
