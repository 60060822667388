import { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from '../context';
import configs from '../configs';
import {
  Card, CardContent, Chip, Box, Container, Typography,
} from '@mui/material';
import DialogItem from '../components/items/DialogItem';
import GridView from '../components/GridView';
import { getItems } from '../services/item-service';
import { renderDecimal } from '../utils/grid';

const renderStatus = (params) => (
  <Typography variant="p">
    {params.value === 1 ? <Chip size="small" label="Active" color="primary" variant="outlined" /> : <Chip size="small" label="Inactive" color="warning" variant="outlined" />}
  </Typography>
);

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 150,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 150,
  },
  {
    field: 'cost',
    type: 'number',
    headerName: 'Cost (RM)',
    renderCell: renderDecimal,
    minWidth: 180,
  },
  {
    field: 'price',
    type: 'number',
    headerName: 'Price (RM)',
    renderCell: renderDecimal,
    minWidth: 180,
  },
  {
    field: 'active',
    headerName: 'Status',
    width: 120,
    renderCell: renderStatus,
    sortable: false,
  },
];


const Items = () => {
  const appContext = useContext(AppContext);
  const [state, setState] = useState({
    data: {
      rows: [],
      count: 0
    }
  });

  const search = async (searchText, page) => {
    try {
      appContext.setLoading(true);
      const result = await getItems(searchText, page, configs.pageSize);
      setState({
        ...state, page, data: result
      });
    } catch (e) {
      appContext.showMessage('error', `Error: ${e}`);
      console.error(e);
    } finally {
      appContext.setLoading(false);
    }
  };

  useEffect(() => {
    search('', 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Invoices | XQ</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Card>
            <CardContent>
              <GridView search={search} columns={columns} data={state.data} SelectedDialog={DialogItem} allowAdd={true}/>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Items;
