import { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from '../context';
import configs from '../configs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import CollectList from '../components/carRentals/CollectList';
import ReturnList from '../components/carRentals/ReturnList';
import SearchCarRentals from '../components/carRentals/SearchCarRentals';
import DialogRentalCollection from '../components/carRentals/DialogCollectCar';
import DialogRentalReturn from '../components/carRentals/DialogReturnCar';
import DialogUpdateCarRental from '../components/carRentals/DialogUpdateCarRental';
import {
  Card, CardContent, Tabs, Tab,
  Box,
  Container,
} from '@mui/material';
import { getCarRentals_external } from '../services/car-service';
import { capitalizeText } from '../utils/etc';

dayjs.extend(utc);
const CarRentals = () => {
  const appContext = useContext(AppContext);
  const [state, setState] = useState({
    tab: 'new',
    search: {
      dateFrom: dayjs().format('YYYY-MM-DD'),
      dateTo: dayjs().add(7, 'day').format('YYYY-MM-DD'),
      text: '',
    },
    data: {
      rows: [],
      count: 0
    },
    selectedRental: null,
    mode: 'collect',
    page: 0,
  });

  useEffect(() => {
    search(0);
  }, [state.tab, state.selectedRental]);

  const changeTab = (event, value) => {
    const filters = {
      text: '',
      dateFrom: dayjs().format('YYYY-MM-DD'),
      dateTo: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    }

    switch (value) {
      case 'new':
        filters.dateTo = dayjs().add(7, 'day').format('YYYY-MM-DD');
        break;
      case 'collected':
        filters.dateFrom = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
        filters.dateTo = dayjs().add(1, 'day').format('YYYY-MM-DD');
        break;
      default:
    }
    setState({ ...state, tab: value, search: filters });
  };

  const search = async (page) => {
    try {
      appContext.setLoading(true);
      const result = await getCarRentals_external(state.tab, state.search.text, state.search.dateFrom, state.search.dateTo, page, state.tab !== 'search' ? 1000 : configs.pageSize);
      setState({
        ...state, page, data: result
      });
    } catch (e) {
      appContext.showMessage('error', `Error: ${e}`);
      console.error(e);
    } finally {
      appContext.setLoading(false);
    }
  };

  const collectCar = (id) => {
    setState({ ...state, selectedRental: id, mode: 'collect' });
  };

  const returnCar = (id) => {
    setState({ ...state, selectedRental: id, mode: 'return' });
  };

  const selectCar = (id) => {
    setState({ ...state, selectedRental: id, mode: 'select' });
  };

  return (
    <>
      <Helmet>
        <title>Car Rentals | XQ</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {(state.selectedRental && state.mode === 'collect') &&
            <DialogRentalCollection id={state.selectedRental} close={() => { setState({ ...state, selectedRental: null }); }} />
          }

          {(state.selectedRental && state.mode === 'return') &&
            <DialogRentalReturn id={state.selectedRental} close={() => { setState({ ...state, selectedRental: null }); }} />
          }

          {(state.selectedRental && state.mode === 'select') &&
            <DialogUpdateCarRental id={state.selectedRental} close={() => { setState({ ...state, selectedRental: null }); }} />
          }

          <Card>
            <CardContent>
              <Tabs
                value={state.tab}
                textColor="primary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
                onChange={changeTab}
              >
                <Tab label="Pick UP" value="new" />
                <Tab label="Return" value="collected" />
                <Tab label="Search" value="search" />
              </Tabs>
              {state.tab === 'new' && <CollectList data={state.data.rows} collectCar={collectCar} />}
              {state.tab === 'collected' && <ReturnList data={state.data.rows} returnCar={returnCar} />}
              {state.tab === 'search' && <SearchCarRentals selectCar={selectCar} />}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default CarRentals;
