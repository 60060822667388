import * as React from 'react';
import {
  Button, TextField, DialogTitle, DialogContent, Dialog, Select, MenuItem, InputLabel, FormControl,
  Grid, Box, Card, CardContent, Typography, AppBar, Toolbar, DialogActions
} from '@mui/material';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import dayjs from 'dayjs';
import {
  dateTimeFormat
} from '../../utils/etc';
import AppContext from '../../context';
import { capitalizeText } from '../../utils/etc';
import { getCarRental_external, updateCarRental_external } from '../../services/car-service';
const Invoice = ({ id, close, mode }) => {
  const newState = {
    id: 'New',
    car: '',
    agreementNo: '',
    deposit: '0',
    depositPaymentType: '',
    collectTime: dayjs().startOf('day').format(dateTimeFormat),
    returnTime: dayjs().startOf('day').add(1, 'day').format(dateTimeFormat),
    collectLocation: '',
    returnLocation: '',
    remark: '',
    status: 'new',
    realCollectTime: null,
    realCollectLocation: '',
    fuel: '0',
    realReturnTime: null,
    realReturnLocation: '',
    damageCharge: '',
    summonCharge: '',
    petrolCharge: '',
    extraHourCharge: '',
    chargesPaymentType: '',
    isNew: true,
  };
  const appContext = React.useContext(AppContext);
  const [error, setError] = React.useState({});
  const [state, setState] = React.useState(newState);

  const loadCarRental = async () => {
    try {
      appContext.setLoading(true);
      const data = await getCarRental_external(id);
      setState({ ...data, status: 'returned' });
    } catch (e) {
      console.error(e);
      appContext.showMessage('error', `Error in procesing this request: ${e}`);
    } finally {
      appContext.setLoading(false);
    }
  };

  React.useState(() => {
    loadCarRental();
  }, []);

  const validateInvoice = () => {
    let maybeError = {
      ...error,
      realReturnTime: state.realReturnTime === null,
      realReturnLocation: state.realReturnLocation === null,
      damageCharge: state.damageCharge === null || state.damageCharge === '',
      summonCharge: state.summonCharge === null || state.summonCharge === '',
      petrolCharge: state.petrolCharge === null || state.petrolCharge === '',
      extraHourCharge: state.extraHourCharge === null || state.extraHourCharge === '',
      chargesPaymentType: state.chargesPaymentType === null || state.chargesPaymentType === ''
    };
    setError(maybeError);
  };

  const handleInputChange = (e) => {
    const newState = { ...state, [e.target.name]: e.target.value }
    setState(newState);
  };

  const save = async () => {
    validateInvoice();
    if (
      !state.realReturnTime
      || !state.realReturnLocation
      || (state.damageCharge === null || state.damageCharge === '')
      || (state.summonCharge === null || state.summonCharge === '')
      || (state.petrolCharge === null || state.petrolCharge === '')
      || (state.extraHourCharge === null || state.extraHourCharge === '')
      || (state.chargesPaymentType === null || state.chargesPaymentType === '')
      ) {
      appContext.showMessage('warning', 'Please fill in all the required fields.');
      return;
    }

    try {
      appContext.setLoading(true);
      await updateCarRental_external(id, state);
      appContext.showMessage('success', `Car rental ${id} collected successfully.`);
      close(true);
    } catch (e) {
      console.error(e);
      appContext.showMessage('error', `Car rental collection failed: ${e.response.data}.`);
    } finally {
      appContext.setLoading(false);
    }
  };

  const formatNumber = (num) => {
    if (!num || num === '') {
      return 0;
    }

    return parseFloat(num);
  }

  return (
    <Dialog fullWidth maxWidth="lg" open onClose={close}  >
      <DialogTitle>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h4" component="div">
              {id}
            </Typography>
            <Button onClick={save} color="inherit">
              save
            </Button> |
            <Button onClick={() => close(true)} color="inherit">
              Close
            </Button>
          </Toolbar>
        </AppBar>

      </DialogTitle>
      <DialogContent style={{ height: '80vh', margin: 15 }}>
        <Card variant="outlined" sx={{
          borderColor: '#B5B5B2', borderWidth: 1
        }}>
          <CardContent>
            <table>
              <tr>
                <td><b>Invoice Id</b></td>
                <td>:</td>
                <td>{state.id}</td>
              </tr>
              <tr>
                <td><b>Name</b></td>
                <td>:</td>
                <td>{state['Invoice.name']}</td>
              </tr>
              <tr>
                <td><b>Contact</b></td>
                <td>:</td>
                <td>{state['Invoice.contact']}</td>
              </tr>
              <tr>
                <td><b>Car</b></td>
                <td>:</td>
                <td>{state.car}</td>
              </tr>
              <tr>
                <td><b>Pick Up</b></td>
                <td>:</td>
                <td>{dayjs(state.collectTime).format('YYYY-MM-DD HH:mm')} {capitalizeText(state.collectLocation)}</td>
              </tr>
              <tr>
                <td><b>Return</b></td>
                <td>:</td>
                <td>{dayjs(state.returnTime).format('YYYY-MM-DD HH:mm')} {capitalizeText(state.returnLocation)}</td>
              </tr>
              <tr>
                <td><b>Real Pick Up</b></td>
                <td>:</td>
                <td>{dayjs(state.realCollectTime).format('YYYY-MM-DD HH:mm')} {capitalizeText(state.realCollectLocation)}</td>
              </tr>
              <tr>
                <td><b>Agreement No.</b></td>
                <td>:</td>
                <td>{state.agreementNo}</td>
              </tr>
              <tr>
                <td><b>Deposit (RM)</b></td>
                <td>:</td>
                <td>{state.deposit}</td>
              </tr>
              <tr>
                <td><b>Payment Type</b></td>
                <td>:</td>
                <td>{capitalizeText(state.depositPaymentType)}</td>
              </tr>
              <tr>
                <td><b>Fuel</b></td>
                <td>:</td>
                <td>{state.fuel}</td>
              </tr>
            </table>
          </CardContent>
        </Card>

        <Card
          variant="outlined"
          sx={{
            marginTop: 2,
            borderColor: '#B5B5B2', borderWidth: 1
          }}
        >
          <CardContent>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel sx={{ marginTop: -2, paddingLeft: 1, paddingBottom: 2, fontSize: 12 }} size="small">Real Return Time</InputLabel>
                  <MobileDateTimePicker
                    size="small"
                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                    onChange={(newValue) => {
                      handleInputChange({ target: { name: 'realReturnTime', value: newValue } });
                    }}
                    value={state.realReturnTime}
                    inputFormat="YYYY-MM-DD HH:mm"
                  />
                </LocalizationProvider>
                {error.realReturnTime && <span style={{ color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15 }}>Real return time can not be blank or earlier than real pick up time.</span>}
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl sx={{ marginTop: 2 }} fullWidth>
                  <InputLabel id="return-real-location-select-label">Real return Location</InputLabel>
                  <Select
                    value={state.realReturnLocation}
                    labelId="return-real-location-select-label"
                    id="realReturnLocation"
                    name="realReturnLocation"
                    label="Real Return Location"
                    size="small"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="airport">Airport</MenuItem>
                    <MenuItem value="hotel">Hotel</MenuItem>
                    <MenuItem value="jetty">Jetty</MenuItem>
                    <MenuItem value="roro">Roro</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                  {error.realReturnLocation && (
                    <span style={{
                      color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                    }}
                    >
                      Invalid input.
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  error={error.damageCharge}
                  helperText={error.damageCharge ? 'Invalid input.' : ''}
                  value={state.damageCharge}
                  fullWidth
                  margin="dense"
                  id="damageCharge"
                  name="damageCharge"
                  label="Damage Charge (RM)"
                  size="small"
                  type="number"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  error={error.summonCharge}
                  helperText={error.summonCharge ? 'Invalid input.' : ''}
                  value={state.summonCharge}
                  fullWidth
                  margin="dense"
                  id="summonCharge"
                  name="summonCharge"
                  label="Summon Charge (RM)"
                  size="small"
                  type="number"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  error={error.petrolCharge}
                  helperText={error.petrolCharge ? 'Invalid input.' : ''}
                  value={state.petrolCharge}
                  fullWidth
                  margin="dense"
                  id="petrolCharge"
                  name="petrolCharge"
                  label="Petrol Charge (RM)"
                  size="small"
                  type="number"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  error={error.extraHourCharge}
                  helperText={error.extraHourCharge ? 'Invalid input.' : ''}
                  value={state.extraHourCharge}
                  fullWidth
                  margin="dense"
                  id="extraHourCharge"
                  name="extraHourCharge"
                  label="Extra Hour Charge (RM)"
                  size="small"
                  type="number"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl sx={{ marginTop: 1 }} fullWidth>
                  <InputLabel id="extra-charges-payment-type-select-label">Extra Charges Payment Type</InputLabel>
                  <Select
                    value={state.chargesPaymentType}
                    labelId="extra-charges-payment-type-select-label"
                    id="chargesPaymentType"
                    name="chargesPaymentType"
                    label="Extra Charges Payment Type"
                    size="small"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="mbb">MBB</MenuItem>
                    <MenuItem value="cash">Cash</MenuItem>
                    <MenuItem value="creditcard">Credit Card</MenuItem>
                  </Select>
                  {error.chargesPaymentType && <span style={{ color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15 }}>Invalid input.</span>}

                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  sx={{ marginTop: 2 }}
                  value={state.remark}
                  fullWidth
                  id="remark"
                  name="remark"
                  label="Remark"
                  multiline
                  rows={5}
                  inputProps={{ maxLength: 5000 }}
                  helperText="Max 5000 characters"
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Box sx={{ padding: 2 }}>
          Total Charges (RM): {(
            formatNumber(state.damageCharge) + formatNumber(state.summonCharge) +
            formatNumber(state.petrolCharge) + formatNumber(state.extraHourCharge)
          ).toFixed(2)}
        </Box>
      </DialogActions>
    </Dialog >
  );
};

export default Invoice;
