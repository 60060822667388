import * as React from 'react';
import {
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Checkbox,
  Select,
  Box,
  MenuItem,
  FormControlLabel,
  FormGroup
} from '@mui/material';
import { getItem, createItem, updateItem } from '../../services/item-service';
import AppContext from '../../context';
import { decimalsCount } from '../../utils/etc';

const newState = {
  category: 'AC',
  item: '',
  id: '',
  name: '',
  cost: '',
  price: '',
  active: true,
};

const DialogItem = ({ id, close }) => {
  const appContext = React.useContext(AppContext);
  const [error, setError] = React.useState({});
  const [data, setData] = React.useState(newState);

  React.useState(() => {
    const loadData = async () => {
      appContext.setLoading(true);
      try {
        if (id && id !== 'New') {
          const data = await getItem(id);
          const idParts = data.id.split('-');
          const formattedData = {
            ...data,
            category: idParts.shift(),
            item: idParts.shift(),
            id: idParts.join('-'),
            cost: parseFloat(data.cost).toFixed(2),
            price: parseFloat(data.price).toFixed(2)
          };
          setData(formattedData);
        };
      } catch (e) {
        appContext.showMessage('error', `Error in procesing this request: ${e}`);
        console.error(e);
      } finally {
        appContext.setLoading(false);
      }
    };
    loadData();
  }, []);

  const validate = () => {
    const maybeError = {
      ...error,
      id: !data.id || data.id === '',
      item: !data.item || data.item === '',
      name: !data.name || data.name === '',
      cost: !data.cost || data.cost === '',
      price: !data.price || data.price === '',
    };
    setError(maybeError);
  };

  const handleInputChange = (e) => {
    if (e.target.name === 'active') {
      setData({ ...data, [e.target.name]: e.target.checked });
      return;
    }

    let { value } = e.target;
    if (e.target.name === 'cost' || e.target.name === 'price') {
      value = e.target.value.replace('e', '');
      if (decimalsCount(value) > 2) {
        value = parseFloat(value).toFixed(2);
      }
    }
    setData({ ...data, [e.target.name]: value });
  };

  const save = async () => {
    validate();
    if ((!data.name || data.name === '')
      || (!data.id || data.id === '')
      || (!data.item || data.item === '')) {
      appContext.showMessage('warning', 'Please fill in all the required fields.');
      return;
    }
    try {
      if (id === 'New') {
        const created = await createItem({ ...data, id: `${data.category}-${data.item}-${data.id}` });
        appContext.showMessage('success', `Item ${created.id} created successfully.`);
      } else {
        await updateItem(`${data.category}-${data.item}-${data.id}`, data);
        appContext.showMessage('success', `Item ${id} updated.`);
      }
      close(true);
    } catch (e) {
      console.error(e);
      appContext.showMessage('error', `Item creation failed: ${e.response.data}`);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={close}>
      <DialogTitle>
        {`Item - ${id}`}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ marginTop: 1, marginBottom: 1 }}>
          <Select
            disabled={id !== 'New'}
            size="small"
            margin="dense"
            variant="outlined"
            value={data.category}
            name="category"
            onChange={handleInputChange}
          >
            <MenuItem value="AC">AC</MenuItem>
            <MenuItem value="AP">AP</MenuItem>
            <MenuItem value="CR">CR</MenuItem>
            <MenuItem value="ET">ET</MenuItem>
            <MenuItem value="PC">PC</MenuItem>
            <MenuItem value="PK">PK</MenuItem>
          </Select>
          <TextField
            sx={{ width: 70 }}
            inputProps={{ maxLength: 4 }}
            disabled={id !== 'New'}
            value={data.item}
            error={error.item}
            helperText={error.item ? 'Invalid input.' : ''}
            id="item"
            name="item"
            size="small"
            label="Item"
            type="text"
            variant="outlined"
            onChange={handleInputChange}
          />
          <TextField
            disabled={id !== 'New'}
            value={data.id}
            error={error.id}
            helperText={error.id ? 'Invalid input.' : ''}
            id="id"
            name="id"
            size="small"
            label="Id"
            type="text"
            variant="outlined"
            onChange={handleInputChange}
          />
        </Box>
        <TextField
          value={data.name}
          error={error.name}
          helperText={error.name ? 'Invalid input.' : ''}
          fullWidth
          margin="dense"
          id="name"
          name="name"
          size="small"
          label="Name"
          type="text"
          variant="outlined"
          onChange={handleInputChange}
        />
        <TextField
          value={data.cost}
          error={error.cost}
          helperText={error.cost ? 'Invalid input.' : ''}
          fullWidth
          margin="dense"
          id="cost"
          name="cost"
          size="small"
          label="Cost"
          type="number"
          variant="outlined"
          onChange={handleInputChange}
        />
        <TextField
          value={data.price}
          error={error.price}
          helperText={error.price ? 'Invalid input.' : ''}
          fullWidth
          margin="dense"
          id="price"
          name="price"
          size="small"
          label="Price"
          type="number"
          variant="outlined"
          onChange={handleInputChange}
        />
        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                checked={data.active}
                onChange={handleInputChange}
                name="active"
              />
            )}
            label="Active"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <>
          <Button variant="contained" onClick={save}>Save</Button>
          <Button onClick={() => close(false)}>Close</Button>
        </>
      </DialogActions>
    </Dialog>
  );
};


export default DialogItem;
