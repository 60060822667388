import { httpPost } from '../utils/api';
import configs from '../configs';


export const login = async (email, password) => {
  const result = await httpPost(`${configs.apiBaseUrl}/api/login`, null, { email, password });
  if (result.jwt) {
    window.localStorage.setItem('xq_jwt', result.jwt);
  }
};


export const getLoginSession = () => {
  try {
    const jwt = window.localStorage.getItem(configs.jwtStorageKey);
    if (jwt) {
      const payload = JSON.parse(atob(jwt.split('.')[1]));
      if (payload.exp > Math.round(new Date().getTime() / 1000)) {
        return payload;
      }
    }
  } catch (e) {
    console.error(e);
  }
  window.localStorage.removeItem(configs.jwtStorageKey);
  return null;
};

export const logout = () => {
  window.localStorage.removeItem(configs.jwtStorageKey);
};

export const changePassword = async (password) => {
  const result = await httpPost(`${configs.apiBaseUrl}/api/changepass`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` },
    { password }
  );
  return result;
};