import { httpGet, httpPost, httpPut } from '../utils/api';
import configs from '../configs';

export const getUsers = async (searchText, page, limit) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/admin/users?search=${searchText}&page=${page}&limit=${limit}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const getUser = async (id) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/admin/users/${id}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const createUser = async (user) => {
  const result = await httpPost(
    `${configs.apiBaseUrl}/api/admin/users`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` },
    user
  );
  return result;
};

export const updateUser = async (id, user) => {
  const result = await httpPut(
    `${configs.apiBaseUrl}/api/admin/users/${id}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` },
    user
  );
  return result;
};