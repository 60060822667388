import { useState, } from 'react';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Card, CardContent,
  Box, Stack,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import FitlerListIcon from '@mui/icons-material/FilterList';

import Monthly from '../components/dashboards/Monthly';
import Yearly from '../components/dashboards/Yearly';
dayjs.extend(utc);

const Dashboard = () => {
  const thisYear = dayjs().year();
  const [filter, setFilter] = useState({
    show: false,
    year: thisYear,
    month: dayjs().format('MM'),
    dateColumn: 'createdAt',
    maybeYear: thisYear,
    maybeMonth: dayjs().format('MM'),
    maybeDateColumn: 'createdAt',
  })

  return (
    <>
      <Helmet>
        <title>Invoices | XQ</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {filter.show &&
            <Dialog maxWidth="lg" onClose={() => setFilter({ ...filter, show: false })} open={filter.show}>
              <DialogTitle>
                <AppBar sx={{ position: 'relative' }}>
                  <Toolbar>
                    <Typography sx={{ flex: 1 }} variant="h4" component="div">
                      Dashboard Filters
                    </Typography>
                    <Button color="inherit" onClick={() => setFilter({
                      ...filter,
                      show: false,
                      year: filter.maybeYear ? filter.maybeYear : filter.year,
                      month: filter.maybeMonth ? filter.maybeMonth : filter.month,
                      dateColumn: filter.maybeDateColumn ? filter.maybeDateColumn : filter.dateColumn
                    })}>
                      Save
                    </Button> |
                    <Button onClick={() => setFilter({ ...filter, show: false })} color="inherit">
                      Cancel
                    </Button>
                  </Toolbar>
                </AppBar>

              </DialogTitle>
              <DialogContent>
                <ToggleButtonGroup
                  onChange={(e, v) => setFilter({ ...filter, maybeYear: v })}
                  size="small"
                  exclusive
                  value={filter.maybeYear ? filter.maybeYear : filter.year}
                  aria-label="text alignment"
                >
                  {[thisYear - 5, thisYear - 4, thisYear - 3, thisYear - 2, thisYear - 1, thisYear, thisYear + 1].map((y) => y >= 2021 ? <ToggleButton key={y} value={y} aria-label="left aligned">
                    {y}
                  </ToggleButton> : null
                  )}

                </ToggleButtonGroup>
                <br />
                <br />
                <ToggleButtonGroup
                  onChange={(e, v) => setFilter({ ...filter, maybeMonth: v })}
                  size="small"
                  exclusive
                  value={filter.maybeMonth ? filter.maybeMonth : filter.month}
                  aria-label="text alignment"
                >
                  {['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((m) => <ToggleButton key={m} value={m} aria-label="left aligned">
                    {m === '00' ? 'Yearly' : m}
                  </ToggleButton>
                  )}

                </ToggleButtonGroup>

                <br />
                <br />
                <ToggleButtonGroup
                  onChange={(e, v) => setFilter({ ...filter, maybeDateColumn: v })}
                  size="small"
                  exclusive
                  value={filter.maybeDateColumn ? filter.maybeDateColumn : filter.dateColumn}
                  aria-label="text alignment"
                >
                  <ToggleButton value="createdAt" aria-label="left aligned">
                    Created
                  </ToggleButton>
                  <ToggleButton value="arrivalTime" aria-label="centered">
                    Arrival
                  </ToggleButton>
                </ToggleButtonGroup>
              </DialogContent>
            </Dialog>
          }
          <Card>
            <CardContent>
              <Stack justifyContent="flex-end" direction="row" spacing={2}>

                <IconButton onClick={() => setFilter({ ...filter, show: true, maybeYear: null, maybeMonth: null, maybeDateColumn: null })} color="primary" aria-label="upload picture" component="span">
                  <Typography variant="subtitle1">
                    {filter.month === '00' ? '' : filter.month + ', '} {filter.year} by {filter.dateColumn}
                  </Typography>
                </IconButton>
              </Stack>
              {filter.month !== '00' && <Monthly year={filter.year} month={filter.month} dateColumn={filter.dateColumn} />}
              {filter.month === '00' && <Yearly year={filter.year} dateColumn={filter.dateColumn} />}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
