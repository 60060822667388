import { useContext, useEffect, useState } from 'react';
import { Chart as ChartJS } from 'chart.js/auto' // this dependency is needed for chartjs bug
import { Chart } from 'react-chartjs-2' // this dependency is needed for chartjs bug
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';
import AppContext from '../../context';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Card, CardContent,
  Box,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { getSales } from '../../services/dashboard-service';
import { formatNumberWithCommas } from '../../utils/etc';
import sumby from 'lodash.sumby';
import range from 'lodash.range';
import uniq from 'lodash.uniq';
dayjs.extend(utc);

const labels = range(1, 13);

const formatSalesLineChartData = (data, reportDate) => {
  const sales = labels.map((l) => {
    const row = data.sales.current.find((r) => r.month === l.toString());
    return row ? row.sales : 0;
  });

  const previousSales = labels.map((l) => {
    const row = data.sales.previous.find((r) => r.month === l.toString());
    return row ? row.sales : 0;
  });

  const datasets = [
    {
      backgroundColor: 'rgba(255, 206, 86, 1.0)',
      barPercentage: 0.5,
      barThickness: 12,
      borderRadius: 4,
      categoryPercentage: 0.5,
      data: sales,
      label: dayjs(reportDate).format('YYYY'),
      maxBarThickness: 10,
      fill: {
        target: 'origin',
        above: 'rgba(255, 206, 86, 0.2)',
      }
    },
    {
      backgroundColor: 'rgba(54, 162, 235, 1)',
      barPercentage: 0.5,
      barThickness: 12,
      borderRadius: 4,
      categoryPercentage: 0.5,
      data: previousSales,
      label: dayjs(reportDate).subtract(1, 'year').format('YYYY'),
      maxBarThickness: 10,
    }
  ];
  return {
    datasets,
    labels,
  };
};

const formatSalesProfitBarChartData = (data) => {
  const sales = labels.map((l) => {
    const row = data.sales.current.find((r) => r.month === l.toString());
    return row ? row.sales : 0;
  });

  const profit = labels.map((l) => {
    const row = data.sales.current.find((r) => r.month === l.toString());
    return row ? row.sales - row.cost : 0;
  });

  const datasets = [
    {
      backgroundColor: 'rgba(255, 206, 86, 1.0)',
      barPercentage: 0.5,
      barThickness: 12,
      borderRadius: 4,
      categoryPercentage: 0.5,
      data: sales,
      label: 'Sales',
      maxBarThickness: 10,
      fill: {
        target: 'origin',
        above: 'rgba(255, 206, 86, 0.2)',
      }
    },
    {
      backgroundColor: 'rgba(54, 162, 235, 1)',
      barPercentage: 0.5,
      barThickness: 12,
      borderRadius: 4,
      categoryPercentage: 0.5,
      data: profit,
      label: 'Profit',
      maxBarThickness: 10,
    }
  ];
  return {
    datasets,
    labels,
  };
};

const formatUserSaleByCategoriesBarChartData = (data, field) => {
  const users = uniq(data.categoriesByUsers.map(u => u.name));
  const ac = users.map((u) => {
    const row = data.categoriesByUsers.find((r) => r.name === u && r.category === 'AC');
    return row ? row[field] : 0;
  });

  const ap = users.map((u) => {
    const row = data.categoriesByUsers.find((r) => r.name === u && r.category === 'AP');
    return row ? row[field] : 0;
  });

  const cr = users.map((u) => {
    const row = data.categoriesByUsers.find((r) => r.name === u && r.category === 'CR');
    return row ? row[field] : 0;
  });

  const et = users.map((u) => {
    const row = data.categoriesByUsers.find((r) => r.name === u && r.category === 'ET');
    return row ? row[field] : 0;
  });

  const pc = users.map((u) => {
    const row = data.categoriesByUsers.find((r) => r.name === u && r.category === 'PC');
    return row ? row[field] : 0;
  });

  const pk = users.map((u) => {
    const row = data.categoriesByUsers.find((r) => r.name === u && r.category === 'PK');
    return row ? row[field] : 0;
  });

  const datasets = [
    {
      backgroundColor: 'rgb(255, 99, 132, 1)',
      barPercentage: 0.5,
      barThickness: 12,
      borderRadius: 4,
      categoryPercentage: 0.5,
      data: ac,
      label: 'Accomodations',
      maxBarThickness: 10,
      fill: {
        target: 'origin',
        above: 'rgba(255, 206, 86, 0.2)',
      }
    },
    {
      backgroundColor: 'rgb(54, 162, 235, 1)',
      barPercentage: 0.5,
      barThickness: 12,
      borderRadius: 4,
      categoryPercentage: 0.5,
      data: ap,
      label: 'Activity Packages',
      maxBarThickness: 10,
    },
    {
      backgroundColor: 'rgb(255, 205, 86, 1)',
      barPercentage: 0.5,
      barThickness: 12,
      borderRadius: 4,
      categoryPercentage: 0.5,
      data: cr,
      label: 'Car Rentals',
      maxBarThickness: 10,
    },
    {
      backgroundColor: 'rgb(88, 214, 141, 1)',
      barPercentage: 0.5,
      barThickness: 12,
      borderRadius: 4,
      categoryPercentage: 0.5,
      data: et,
      label: 'Entrance Tickets',
      maxBarThickness: 10,
    },
    {
      backgroundColor: 'rgb(255, 87, 51, 1)',
      barPercentage: 0.5,
      barThickness: 12,
      borderRadius: 4,
      categoryPercentage: 0.5,
      data: pc,
      label: 'Private Cruises',
      maxBarThickness: 10,
    },
    {
      backgroundColor: 'rgb(218, 247, 166, 1)',
      barPercentage: 0.5,
      barThickness: 12,
      borderRadius: 4,
      categoryPercentage: 0.5,
      data: pk,
      label: 'Packages',
      maxBarThickness: 10,
    }
  ];
  return {
    datasets,
    labels: users.map(u => {
      let total = 0;
      data.categoriesByUsers.filter((r) => r.name === u).map(d => total += parseFloat(d[field]));
      total = Math.round(total * 100) / 100;
      return [u, formatNumberWithCommas(total)]
    }),
  };
};

const getUserSalesCategoriesChartOptions = (theme, title) => ({
  animation: false,
  cornerRadius: 20,
  layout: { padding: 0 },
  legend: { display: false },
  maintainAspectRatio: false,
  responsive: true,
  indexAxis: 'x',
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
          stepSize: 1,
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          stepSize: 1,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 2,
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    ]
  },
  tooltips: {
    backgroundColor: theme.palette.background.paper,
    bodyFontColor: theme.palette.text.secondary,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    enabled: true,
    footerFontColor: theme.palette.text.secondary,
    intersect: false,
    mode: 'index',
    titleFontColor: theme.palette.text.primary
  },
  plugins: {
    title: {
      display: true,
      align: 'start',
      font: {
        size: 16,
      },
      text: title,
      padding: {
        top: 10,
        bottom: 0
      }
    }
  }
});

const getSalesLineChartOptions = (theme, title) => ({
  animation: false,
  cornerRadius: 20,
  layout: { padding: 0 },
  legend: { display: false },
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    ]
  },
  tooltips: {
    backgroundColor: theme.palette.background.paper,
    bodyFontColor: theme.palette.text.secondary,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    enabled: true,
    footerFontColor: theme.palette.text.secondary,
    intersect: false,
    mode: 'index',
    titleFontColor: theme.palette.text.primary
  },
  plugins: {
    title: {
      display: true,
      align: 'start',
      font: {
        size: 16,
      },
      text: title,
      padding: {
        top: 10,
        bottom: 0
      }
    }
  }
});

const formatCategoryPieChart = (data) => ({
  labels: [
    'Accomodation',
    'Activity Packages',
    'Car Rentals',
    'Entrance Tickets',
    'Private Cruises',
    'Package'
  ],
  datasets: [
    {
      label: 'Items Categories',
      data: [
        data.items.categories.accomodations,
        data.items.categories.activityPackages,
        data.items.categories.cars,
        data.items.categories.entranceTickets,
        data.items.privateCruises,
        data.items.packages
      ],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)',
        'rgb(88, 214, 141)',
        'rgb(255, 87, 51)',
        'rgb(218, 247, 166)'
      ],
      hoverOffset: 4
    }
  ]
});

const formatTopItemsPieChart = (data) => ({
  labels: data.map((o) => o.itemGroup),
  datasets: [
    {
      label: 'Items Categories',
      data: data.map((o) => o.total),
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)',
        'rgb(88, 214, 141)',
        'rgb(255, 87, 51)',
        'rgb(218, 247, 166)',
        'rgb(255, 195, 0)',
        'rgb(199, 0, 57)',
        'rgb(30, 132, 73)',
        'rgb(202, 111, 30)',
      ],
      hoverOffset: 4
    }
  ]
});

const formatTopSourcesPieChart = (data) => ({
  labels: data.map((o) => o.source),
  datasets: [
    {
      label: 'Items Categories',
      data: data.map((o) => o.total),
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)',
        'rgb(88, 214, 141)',
        'rgb(255, 87, 51)',
        'rgb(218, 247, 166)',
        'rgb(255, 195, 0)',
        'rgb(199, 0, 57)',
        'rgb(30, 132, 73)',
        'rgb(202, 111, 30)',
      ],
      hoverOffset: 4
    }
  ]
});

const Yearly = ({ year, dateColumn }) => {
  const reportDate = dayjs(`${year}-01-01`);
  const theme = useTheme();
  const appContext = useContext(AppContext);
  const [state, setState] = useState({});
  const getData = async () => {
    try {
      appContext.setLoading(true);
      const result = await getSales(year, '00', dateColumn);
      setState({ ...state, data: result });
    } catch (e) {
      appContext.showMessage('error', `Error: ${e}`);
      console.error(e);
    } finally {
      appContext.setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [dateColumn, year]);

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Card
          sx={{ height: '100%' }}
        >
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                >
                  Sales
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  {state.data ? `RM ${formatNumberWithCommas(sumby(state.data.sales.current, (o) => parseFloat(o.sales)).toFixed(2))}` : ''}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                pt: 2,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{
                  mr: 1
                }}
                variant="body2"
              >
                {state.data ? `RM ${formatNumberWithCommas(sumby(state.data.sales.previous, (o) => parseFloat(o.sales)).toFixed(2))}` : ''}
              </Typography>
              <Typography
                color="textSecondary"
                variant="caption"
              >
                last year
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Card
          sx={{ height: '100%' }}
        >
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                >
                  Profit
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  {state.data ? `RM ${formatNumberWithCommas(sumby(state.data.sales.current, (o) => parseFloat(o.sales) - parseFloat(o.cost)).toFixed(2))}` : ''}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                pt: 2,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{
                  mr: 1
                }}
                variant="body2"
              >
                {state.data ? `RM ${formatNumberWithCommas(sumby(state.data.sales.previous, (o) => parseFloat(o.sales) - parseFloat(o.cost)).toFixed(2))}` : ''}
              </Typography>
              <Typography
                color="textSecondary"
                variant="caption"
              >
                last year
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Card
          sx={{ height: '100%' }}
        >
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                >
                  Gross Profit Percentage
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  {state.data ? `${((sumby(state.data.sales.current, (o) => parseFloat(o.sales) - parseFloat(o.cost)) / sumby(state.data.sales.current, (o) => parseFloat(o.sales))) * 100).toFixed(2)} %` : ''}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                pt: 2,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{
                  mr: 1
                }}
                variant="body2"
              >
                {state.data && state.data.sales.previous.length > 0 ? `${((sumby(state.data.sales.previous, (o) => parseFloat(o.sales) - parseFloat(o.cost)) / sumby(state.data.sales.previous, (o) => parseFloat(o.sales))) * 100).toFixed(2)} %` : '0 %'}
              </Typography>
              <Typography
                color="textSecondary"
                variant="caption"
              >
                last year
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Card
          sx={{ height: '100%' }}
        >
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                >
                  # of Guests
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  {state.data ? `${formatNumberWithCommas(sumby(state.data.sales.current, (o) => o.guests))}` : ''}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                pt: 2,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{
                  mr: 1
                }}
                variant="body2"
              >
                {state.data ? `${formatNumberWithCommas(sumby(state.data.sales.previous, (o) => o.guests))}` : ''}
              </Typography>
              <Typography
                color="textSecondary"
                variant="caption"
              >
                last year
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
        {state.data && <Line
          height={300}
          width="100%"
          data={formatSalesLineChartData(state.data, reportDate)}
          options={getSalesLineChartOptions(theme, `Sales - ${reportDate.format('YYYY')} vs ${reportDate.subtract(1, 'year').format('YYYY')}`)}
        />}

      </Grid>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
        {state.data && <Bar
          height={300}
          width="100%"
          data={formatSalesProfitBarChartData(state.data)}
          options={getSalesLineChartOptions(theme, `Sales vs Profit - ${reportDate.format('YYYY')}`)}
        />
        }
      </Grid>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
        {state.data && <Bar
          height={600}
          width="100%"
          data={formatUserSaleByCategoriesBarChartData(state.data, 'quantity')}
          options={getUserSalesCategoriesChartOptions(theme, `Categories by Sales (Quantity) - ${reportDate.format('MMMM, YYYY')}`)}
        />
        }
      </Grid>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
        {state.data && <Bar
          height={600}
          width="100%"
          data={formatUserSaleByCategoriesBarChartData(state.data, 'total')}
          options={getUserSalesCategoriesChartOptions(theme, `Categories by Sales (Values) - ${reportDate.format('MMMM, YYYY')}`)}
        />
        }
      </Grid>
      <Grid item lg={4} sm={6} xl={4} xs={12}>
        {state.data && <Card><CardContent>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            Items by Categories
          </Typography>
          <Pie
            height={300}
            width="100%"
            data={formatCategoryPieChart(state.data)}
          />
        </CardContent></Card>}
      </Grid>
      <Grid item lg={4} sm={6} xl={4} xs={12}>
        {state.data && <Card><CardContent>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            Top 10 Accomodations
          </Typography>
          <Doughnut
            height={300}
            width="100%"
            data={formatTopItemsPieChart(state.data.items.top.accomodations)}
          />
        </CardContent></Card>}
      </Grid>
      <Grid item lg={4} sm={6} xl={4} xs={12}>
        {state.data && <Card><CardContent>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            Top 10 Activity Packages
          </Typography>
          <Doughnut
            height={300}
            width="100%"
            data={formatTopItemsPieChart(state.data.items.top.activityPackages)}
          />
        </CardContent></Card>}
      </Grid>
      <Grid item lg={4} sm={6} xl={4} xs={12}>
        {state.data && <Card><CardContent>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            Top 10 Car Rentals
          </Typography>
          <Doughnut
            height={300}
            width="100%"
            data={formatTopItemsPieChart(state.data.items.top.cars)}
          />
        </CardContent></Card>}
      </Grid>
      <Grid item lg={4} sm={6} xl={4} xs={12}>
        {state.data && <Card><CardContent>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            Top 10 Entrance Tickets
          </Typography>
          <Doughnut
            height={300}
            width="100%"
            data={formatTopItemsPieChart(state.data.items.top.entranceTickets)}
          />
        </CardContent></Card>}
      </Grid>
      <Grid item lg={4} sm={6} xl={4} xs={12}>
        {state.data && <Card><CardContent>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            Top 10 Private Cruises
          </Typography>
          <Doughnut
            height={300}
            width="100%"
            data={formatTopItemsPieChart(state.data.items.top.privateCruises)}
          />
        </CardContent></Card>}
      </Grid>
      <Grid item lg={4} sm={6} xl={4} xs={12}>
        {state.data && <Card><CardContent>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            Top 10 Packages
          </Typography>
          <Doughnut
            height={300}
            width="100%"
            data={formatTopItemsPieChart(state.data.items.top.packages)}
          />
        </CardContent></Card>}
      </Grid>
      <Grid item lg={4} sm={6} xl={4} xs={12}>
        {state.data && <Card><CardContent>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            Top 10 Sales Channels
          </Typography>
          <Doughnut
            height={300}
            width="100%"
            data={formatTopSourcesPieChart(state.data.sources)}
          />
        </CardContent></Card>}
      </Grid>
    </Grid>
  );
};

export default Yearly;
