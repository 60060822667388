import { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from '../context';
import configs from '../configs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Card, CardContent,
  Box,
  InputLabel,
  Select,
  FormControl,
  Typography,
  Popover,
  OutlinedInput,
  Container,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import DialogInvoice from '../components/invoices/DialogInvoice';
import { getReservationVouchers, getOptions } from '../services/invoice-service';
import GridView from '../components/GridView';

dayjs.extend(utc);

const renderDueDate = (param) => {
  const style = {};
  if (dayjs(param.value) <= dayjs()) {
    style.color = 'red';
  }
  return <h4 style={style}>{param.value}</h4>;
};

const RenderLongText = (params) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const isLongText = params.value && params.value.length > 20;
  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {isLongText ? params.value.substring(0, 50) + '...' : params.value}
      </Typography>
      {isLongText &&
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1, overflowX: 'auto', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{params.value}</Typography>
        </Popover>
      }
    </div>
  );
}

const columns = [
  {
    field: 'dueDate', headerName: 'Due Date', renderCell: renderDueDate, width: 100
  },
  {
    field: 'invoiceId',
    headerName: 'Invoice ID',
    width: 110,
  },
  {
    field: 'billNo',
    headerName: 'RV No.',
    width: 110,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
  },
  {
    field: 'cost',
    headerName: 'Cost  (RM)',
    width: 100,
    type: 'number',
    editable: false,
    sortable: false,
    valueGetter: (params) => parseFloat(params.value).toFixed(2)
  },
  {
    field: 'payment',
    headerName: 'Payment  (RM)',
    width: 130,
    type: 'number',
    editable: false,
    sortable: false,
    valueGetter: (params) => parseFloat(params.value).toFixed(2)
  },
  {
    field: 'balance',
    headerName: 'Balance (RM)',
    width: 120,
    type: 'number',
    editable: false,
    sortable: false,
    valueGetter: (params) => parseFloat(params.value).toFixed(2)
  },
  {
    field: 'desc',
    headerName: 'Remark',
    minWidth: 100,
    flex: 1,
    editable: false,
    sortable: false,
    renderCell: RenderLongText,
  },
];

const ReservationVouchers = () => {
  const appContext = useContext(AppContext);
  const [state, setState] = useState({
    data: {
      rows: [],
      count: 0
    },
    selectedInvoice: null,
    statuses: ['new', 'booking', 'costing', 'invoice', 'paid', 'unpaid'],
    paymentTypes: ['cash', 'creditcard', 'contra', 'revert']
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    loadOptions();
    search('', 0);
  }, []);

  const loadOptions = async () => {
    try {
      const options = await getOptions();
      appContext.setOptions(options);
    } catch {
      appContext.showMessage('Error', 'Some error had occured in getting options. Please try again.');
    }
  }

  const search = async (searchText, page) => {
    try {
      appContext.setLoading(true);
      const result = await getReservationVouchers(state.statuses, searchText, page, configs.pageSize);
      setState({
        ...state, page, invoice: { id: 'New', open: false }, data: result
      });
    } catch (e) {
      appContext.showMessage('error', `Error: ${e}`);
      console.error(e);
    } finally {
      appContext.setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Invoices | XQ</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Card>
            <CardContent>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="status-checkbox-label">Statuses</InputLabel>
                  <Select
                    labelId="status-checkbox-label"
                    id="statuses"
                    name="statuses"
                    size="small"
                    label="statuses"
                    multiple
                    value={state.statuses}
                    onChange={handleChange}
                    input={<OutlinedInput label="Statuses" />}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    <MenuItem value="new">
                      <Checkbox checked={state.statuses.indexOf('new') > -1} />
                      <ListItemText primary="New" />
                    </MenuItem>
                    <MenuItem value="booking">
                      <Checkbox checked={state.statuses.indexOf('booking') > -1} />
                      <ListItemText primary="Booking" />
                    </MenuItem>
                    <MenuItem value="costing">
                      <Checkbox checked={state.statuses.indexOf('costing') > -1} />
                      <ListItemText primary="Costing" />
                    </MenuItem>
                    <MenuItem value="invoice">
                      <Checkbox checked={state.statuses.indexOf('invoice') > -1} />
                      <ListItemText primary="Invoice" />
                    </MenuItem>
                    <MenuItem value="unpaid">
                      <Checkbox checked={state.statuses.indexOf('unpaid') > -1} />
                      <ListItemText primary="Unpaid" />
                    </MenuItem>
                    <MenuItem value="paid">
                      <Checkbox checked={state.statuses.indexOf('paid') > -1} />
                      <ListItemText primary="Paid" />
                    </MenuItem>
                    <MenuItem value="operating">
                      <Checkbox checked={state.statuses.indexOf('operating') > -1} />
                      <ListItemText primary="Operating" />
                    </MenuItem>
                    <MenuItem value="completed">
                      <Checkbox checked={state.statuses.indexOf('completed') > -1} />
                      <ListItemText primary="Completed" />
                    </MenuItem>
                    <MenuItem value="cancelled">
                      <Checkbox checked={state.statuses.indexOf('cancelled') > -1} />
                      <ListItemText primary="Cancelled" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {/* <Box sx={{ mt: 2}}>
                <FormControl fullWidth>
                  <InputLabel id="paymentType-checkbox-label">Payment Types</InputLabel>
                  <Select
                    labelId="paymentType-checkbox-label"
                    id="paymentTypes"
                    name="paymentTypes"
                    size="small"
                    label="Payment Types"
                    multiple
                    value={state.paymentTypes}
                    onChange={handleChange}
                    input={<OutlinedInput label="Payment Types" />}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    <MenuItem value="cash">
                      <Checkbox checked={state.paymentTypes.indexOf('cash') > -1} />
                      <ListItemText primary="Cash" />
                    </MenuItem>
                    <MenuItem value="creditcard">
                      <Checkbox checked={state.paymentTypes.indexOf('creditcard') > -1} />
                      <ListItemText primary="Credit Card" />
                    </MenuItem>
                    <MenuItem value="contra">
                      <Checkbox checked={state.paymentTypes.indexOf('contra') > -1} />
                      <ListItemText primary="Contra" />
                    </MenuItem>
                    <MenuItem value="revert">
                      <Checkbox checked={state.paymentTypes.indexOf('revert') > -1} />
                      <ListItemText primary="Revert" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box> */}
              <GridView search={search} columns={columns} data={state.data} SelectedDialog={DialogInvoice} allowAdd={false} selectedRowIdColumn="invoiceId" />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ReservationVouchers;
