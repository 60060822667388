export const PaymentTypes = [
  { id: 'aliPay', name: 'AliPay'},
  { id: 'cash', name: 'Cash'},
  { id: 'creditCard', name: 'Credit Card'},
  { id: 'ipay88', name: 'Ipay88'},
  { id: 'kkday', name: 'KK Day'},
  { id: 'lazada', name: 'Lazada'},
  { id: 'mbb', name: 'MBB'},
  { id: 'pbb', name: 'PBB'},
  { id: 'wechatPay', name: 'wechat PAY'},
  { id: 'sharkbate', name: 'SharkBate'},
  { id: 'shopee', name: 'Shopee'},
  { id: 'tripadvisor', name: 'Tripadvisor'},  
  { id: 'revert', name: 'Revert'},
];

export const Sources = [
  { id: 'agent', name: 'Agent'},
  { id: 'boss', name: 'Boss'},
  { id: 'fb', name: 'FB'},
  { id: 'fbPromo', name: 'FB Promo'},
  { id: 'instagram', name: 'Instagram'},
  { id: 'justTravel', name: 'Just Travel'},
  { id: 'kkDay', name: 'KK Day'},
  { id: 'lazada', name: 'Lazada'},
  { id: 'shirley-gt', name: 'Shirley - GT'},
  { id: 'shopee', name: 'Shopee'},
  { id: 'tripadvisor', name: 'tripadvisor'},
  { id: 'walkIn', name: 'Walk In'},
  { id: 'web', name: 'Web'},
  { id: 'wechat', name: 'Wechat'},
  { id: 'whatsapp', name: 'whatsapp'},
  { id: 'whatsappPromo', name: 'Whatsapp Promo'},
  { id: 'xiaoHongShu', name: 'Xiao Hong Shu'},
  { id: 'other', name: 'Others'}
]