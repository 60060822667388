import { httpGet, httpPost, httpPut } from '../utils/api';
import configs from '../configs';

export const getItems = async (searchText, page, limit) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/admin/items?search=${searchText}&page=${page}&limit=${limit}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const getItem = async (id) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/admin/items/${id}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const createItem = async (item) => {
  const result = await httpPost(
    `${configs.apiBaseUrl}/api/admin/items`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` },
    item
  );
  return result;
};

export const updateItem = async (id, item) => {
  const result = await httpPut(
    `${configs.apiBaseUrl}/api/admin/items/${id}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` },
    item
  );
  return result;
};