import { httpGet, httpPost, httpPut } from '../utils/api';
import configs from '../configs';

export const getOptions = async () => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/internal/options`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const getInvoices = async (status, searchText, page, limit) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/internal/invoices?status=${status}&search=${searchText}&page=${page}&limit=${limit}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const getReservationVouchers = async (statuses, searchText, page, limit) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/internal/reservation-vouchers?statuses=${statuses}&search=${searchText}&page=${page}&limit=${limit}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const getInvoice = async (id) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/internal/invoices/${id}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const createInvoice = async (invoice, items, payments, reservationVouchers) => {
  const result = await httpPost(`${configs.apiBaseUrl}/api/internal/invoices`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` },
    { invoice, items, payments, reservationVouchers }
  );
  return result;
};

export const updateInvoice = async (id, invoice, items, payments, reservationVouchers) => {
  const result = await httpPut(`${configs.apiBaseUrl}/api/internal/invoices/${id}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` },
    { invoice, items, payments, reservationVouchers }
  );
  return result;
};

export const getInvoiceLogs = async (id, page, limit) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/internal/invoices/${id}/logs?page=${page}&limit=${limit}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};


export const getInvoices_external = async (status, searchText, page, limit) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/external/invoices?status=${status}&search=${searchText}&page=${page}&limit=${limit}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const getInvoice_external = async (id) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/external/invoices/${id}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const updateOperationStatus_external = async (id, status) => {
  const result = await httpPost(`${configs.apiBaseUrl}/api/external/invoices/${id}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` },
    {status});
  return result;
};

export const getInvoice_customer = async (id, passcode) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/customers/invoices/${id}?passcode=${passcode}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const uploadSlip_customer = async (id, passcode, date, file, amount) => {
  const formData = new FormData();
  formData.append('date', date);
  formData.append('amount', amount);
  formData.append('file', file);
  const result = await httpPost(`${configs.apiBaseUrl}/api/customers/invoices/${id}?passcode=${passcode}`, { 'Content-Type': 'multipart/form-data' }, formData);
  return result;
};

export const getInvoicePaymentSlip = async (attachment) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/internal/attachments/${attachment}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const getInvoiceStatus_customer = async (id) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/customers/invoices/${id}/status`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};