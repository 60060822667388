import dayjs from 'dayjs';
import * as React from 'react';
import AppContext from '../../context';
import utc from 'dayjs/plugin/utc';
import configs from '../../configs';
import {
  Card, CardContent,
  Grid,
  Chip,
  Box,
  TextField,
  Typography,
  Button,
  InputAdornment,
  SvgIcon,
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import { getCarRentals_external } from '../../services/car-service';
import { capitalizeText } from '../../utils/etc';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
dayjs.extend(utc);
const SearchCarRentals = ({ selectCar }) => {
  const [state, setState] = React.useState({
    dates: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
    searchText: '',
    page: 0,
  });
  const appContext = React.useContext(AppContext);
  const renderList = (data) => (
    <Grid container spacing={1}>
      {data.rows.map(r => (
        <Grid item lg={4} md={6} xs={12}>
          <Card sx={{ cursor: 'pointer', backgroundColor: '#F2F2F2' }} onClick={() => selectCar(r.id)}>
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                <span>{r.id} </span>
                {r.status === 'new' && <Chip color="primary" label="To Pick Up" size="small" variant="outlined"></Chip>}
                {r.status === 'collected' && <Chip color="primary" label="To Return" size="small" variant="outlined"></Chip>}
                {r.status === 'returned' && <Chip color="success" label="Completed" size="small" variant="outlined"></Chip>}
                <br /><br/>
                {r['Invoice.name']}<br />
                {r['Invoice.contact']} <br />
                {r.car} <br />
                {dayjs(r.collectTime).format('YYYY-MM-DD HH:mm')} {capitalizeText(r.collectLocation)} <br/>
                {dayjs(r.returnTime).format('YYYY-MM-DD HH:mm')} {capitalizeText(r.returnLocation)} <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const search = async (page) => {
    try {
      appContext.setLoading(true);
      const result = await getCarRentals_external('search', state.searchText, state.dates[0], state.dates[1], page, configs.pageSize);
      setState({
        ...state, page, data: result
      });
    } catch (e) {
      appContext.showMessage('error', `Error: ${e}`);
      console.error(e);
    } finally {
      appContext.setLoading(false);
    }
  };


  return (
    <div style={{ paddingTop: 20 }}>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          startText="Start Date"
          endText="End Date"
          value={state.dates}
          onChange={(newValue) => {
            const newDates = [newValue[0].format('YYYY-MM-DD')];
            if (newValue[1]) {
              newDates.push(newValue[1].format('YYYY-MM-DD'));
            }
            setState({ ...state, dates: newDates });
          }}
          inputFormat="YYYY-MM-DD"
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField size="small" {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField size="small" {...endProps} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          paddingTop: 2,
        }}
      >
        <TextField
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                >
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          placeholder="Search..."
          variant="outlined"
          value={state.searchText}
          onChange={(e) => setState({ ...state, searchText: e.target.value })}
        />
        <Button
          onClick={() => search(0)}
          style={{ marginLeft: 10 }}
          variant="outlined"
          size="small"
        >
          Search
        </Button>
      </Box>
      <br />
      {state.data && <> {renderList(state.data)}</>}
    </div>
  );
};

export default SearchCarRentals;
