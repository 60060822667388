import { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import AppContext from '../context';
import { print } from '../utils/print_minimal';
import {
  Card, CardContent, Tabs, Tab,
  Box,
  Button,
  TextField,
  Container,
  Popover,
} from '@mui/material';
import { getInvoice_customer, getInvoiceStatus_customer } from '../services/invoice-service';
import Payments from '../components/customers/Payments';

const ViewInvoice = () => {
  const appContext = useContext(AppContext);
  const [state, setState] = useState({
    validated: false,
  });
  const [transactions, setTransactions] = useState({
    validated: false,
  });
  const { id } = useParams();
  const handleInputChanged = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  const displayInvoice = (data) => {
    try {
    var iframe = document.createElement('iframe');
    // iframe.frameBorder = 0;
    iframe.id = 'view-invoice-iframe';
    iframe.height = '100%'
    iframe.width = '100%'
    var html = print(data, data.InvoiceItems, data.InvoicePayments, false);
    document.getElementById('iframe-container').appendChild(iframe);
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(html);
    iframe.contentWindow.document.close();
    } catch(ex){
      appContext.showMessage('error', ex.message);
    }
  }

  useEffect(() => {
    const getInvoiceStatus = async () => {
      try {
        appContext.setLoading(true);
        const result = await getInvoiceStatus_customer(id);
        setState({
          ...state,
          validated: false,
          status: result.status
        });
        if (!result || result.status === 'cancelled') {
          appContext.showMessage('error', 'Invoice does not exist or has been cancelled');
        }
      } catch (e) {
        appContext.showMessage('error', 'Unexpected error had occurred. Please contact our system administrator.');
        console.log(e)
      } finally {
        appContext.setLoading(false);
      }
    }

    getInvoiceStatus();
  }, []);

  const getInvoice = async () => {
    try {
      appContext.setLoading(true);
      const result = await getInvoice_customer(id, state.passcode);
      setState({
        ...state,
        validated: true,
        data: result
      });
      setTransactions(result.InvoicePayments);
      displayInvoice(result);
    } catch (e) {
      switch (e.response && e.response.status) {
        case 401: appContext.showMessage('error', 'Incorrect pass code. Please make sure you have key in the right pass code.');
          return;
        case 404: appContext.showMessage('error', 'Unable to retrieve the invoice. Please make sure the invoice id is correct.');
          return;
        default:
      }
      appContext.showMessage('error', 'Unexpected error had occurred. Please contact our system administrator.');
      console.log(e)
    } finally {
      appContext.setLoading(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>Invoices | XQ</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {(state.status && state.status !== 'cancelled') &&
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '75vh',
                    justifyContent: 'center'
                  }}
                >
                  {state.validated && <>
                    <div>
                      <Button
                        onClick={() => setState({ ...state, makePayment: true })}
                        color="primary"
                        type="submit"
                      >
                        Make Payment
                      </Button>
                      |
                      <Button
                        onClick={() => {
                          window.document.getElementById("view-invoice-iframe").contentWindow.print()
                        }}
                        color="primary"
                        type="submit"
                      >
                        Print
                      </Button>
                    </div>
                    <div style={{ padding: 5, height: '100%', width: '100%' }} id="iframe-container"></div>
                  </>}

                  {state.makePayment && <Payments passcode={state.passcode} invoiceId={id} updatePayments={setTransactions} payments={transactions} close={() => setState({ ...state, makePayment: false })} />}
                  {!state.validated &&
                    <Container maxWidth="sm">
                      <h1>Invoice {id}</h1>
                      <TextField
                        fullWidth
                        label="Pass Code"
                        helperText="Key in last 4 digit of your phone number to retrieve the invoice."
                        margin="normal"
                        value={state.passcode}
                        name="passcode"
                        type="text"
                        variant="outlined"
                        onChange={handleInputChanged}
                      />
                      <Box sx={{ py: 2 }}>
                        <Button
                          onClick={getInvoice}
                          color="primary"
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Get Invoice
                        </Button>
                      </Box>
                    </Container>}
                </Box>
              </CardContent>
            </Card>
          }
        </Container>
      </Box>
    </>
  );
};

export default ViewInvoice;
