import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@mui/material';
import AppContext from '../context';
import { login } from '../services/auth-service';
import { getLoginSession } from '../services/auth-service';
const Login = () => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [formData, setFormData] = useState({ email: '', password: '' });


  const onLoginClick = async (event) => {
    try {
      appContext.setLoading(true);
      await login(formData.email, formData.password);
      switch (getLoginSession().role) {
        case 1: navigate('/app/dashboard', { replace: true });
          break;
        case 0:
        case 2: navigate('/app/invoices', { replace: true });
          break;
        case 3: navigate('/app/tasks', { replace: true });
          break;
        default: navigate('/login', { replace: true });
      }
      window.location.reload(false);
    } catch (error) {
      appContext.showMessage('error', 'Login failed. Either email or password is incorrect.')
    } finally {
      appContext.setLoading(false);
      event.preventDefault();
    }
  }

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Helmet>
        <title>小强兰卡威旅程 Langkawi Tour</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          minHeight: "80vh",
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Box
            component="form"
            noValidate
            autoComplete="off"
          >
            <Box sx={{ mb: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Sign in
              </Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth={true}
                id="email"
                name="email"
                label="Email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </Box>
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth={true}
                id="password"
                name="password"
                label="password"
                type="password"
                value={formData.password}
                onChange={handleInputChange}
              />
            </Box>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={onLoginClick}
            >Login</Button>
            <Box sx={{ mt: 1, textAlign: 'center' }}>
              <Typography variant="caption">
                v1.0.0
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Login;
