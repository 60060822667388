import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from '../context';
import {
  Card, CardContent, Box, Container, Typography, Grid, TextField, Button
} from '@mui/material';
import { getLoginSession, changePassword } from '../services/auth-service';

const Account = () => {
  const loginSession = getLoginSession();
  const appContext = useContext(AppContext);
  const [state, setState] = useState({
    password: null,
    confirmPassword: null,
  });
  const [error, setError] = useState({});

  const handleInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const changePass = async () => {
    try {
      appContext.setLoading(true);
      if (!state.password || state.password === '') {
        setError({ password: true });
        return;
      }
      if (state.password !== state.confirmPassword) {
        setError({ confirmPassword: true });
        return;
      }
      setError({});
      await changePassword(state.password);
      appContext.showMessage('success', 'Password successfully changed.');
    } catch (e) {
      console.error(e);
      appContext.showMessage('error', `Change password failed: ${e.response.data}`);
    } finally {
      appContext.setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Account | XQ</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} xs={12}>
                  <Typography variant="h4">Profile</Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    label="Email"
                    name="email"
                    required
                    variant="outlined"
                    value={loginSession.email}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    label="Name"
                    name="name"
                    required
                    variant="outlined"
                    value={loginSession.name}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Typography variant="h4">Change Password</Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    error={error.password}
                    helperText={error.password ? 'Password cannot be blank' : ''}
                    onChange={handleInputChange}
                    fullWidth
                    label="New Password"
                    type="password"
                    value={state.password}
                    name="password"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    error={error.password}
                    helperText={error.confirmPassword ? 'Confirm password does not matched' : ''}
                    onChange={handleInputChange}
                    value={state.confirmPassword}
                    fullWidth
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Button color="primary" variant="contained" onClick={changePass}>
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Account;
