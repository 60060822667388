import * as React from 'react';
import { DataGrid, GridToolbarContainer, GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid';
import { Button, Popover, Chip, Box, Typography, Stack } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import DialogPayment from './Payment';
import dayjs from 'dayjs';
import { getLoginSession } from '../../services/auth-service';
import { capitalizeText } from '../../utils/etc';
import AppContext from '../../context';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import { getInvoicePaymentSlip } from '../../services/invoice-service';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { PaymentTypes } from '../../configs/system';

const RenderLongText = (params) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const isLongText = params.value && params.value.length > 20;
  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {isLongText ? params.value.substring(0, 20) + '...' : params.value}
      </Typography>
      {isLongText &&
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{params.value}</Typography>
        </Popover>
      }
    </div>
  );
}
const RenderStatus = (params) => {
  switch (params.value) {
    case 'approved':
      return <Chip size="small" label="Approved" color="success" variant="outlined" />;
    case 'pending':
      return <Chip size="small" label="Pending" color="warning" variant="outlined" />;
    case 'rejected':
      return <Chip size="small" label="Rejected" color="error" variant="outlined" />;
    default: return <></>;
  }
}

const Payments = ({ invoiceId, payments, updatePayments }) => {
  const appContext = React.useContext(AppContext);
  const [selectedPayment, setSelectedPayment] = React.useState(null);
  const apiRef = useGridApiRef();

  const RenderPayment = (params) => {
    const payment = payments.find((p) => p.id === params.id);
    if (payment.isNew) {
      return <>
        {capitalizeText(params.value)}  <FiberNewOutlinedIcon color="primary" />
      </>;
    } else {
      return <>{capitalizeText(params.value)}</>;
    }
  }

  const RenderType = (value) => {
    const type = PaymentTypes.find((t) => t.id === value);
    return type ? type.name : '';
  }

  const GridToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button color="primary" onClick={() => setSelectedPayment({
          id: uuidv4(),
          date: dayjs().format('YYYY-MM-DD'),
          type: '',
          payment: '',
          amount: '',
          remark: '',
          status: 'approved',
          approver: getLoginSession().sub,
          isNew: true,
        })}>
          Add Payment
        </Button>
      </GridToolbarContainer >
    );
  }

  const columns = [
    {
      field: 'payment',
      headerName: 'Payment',
      width: 100,
      sortable: false,
      editable: false,
      renderCell: RenderPayment
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      sortable: false,
      editable: false,
      valueGetter: (params) => RenderType(params.value)
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      editable: false,
      sortable: false,
      valueGetter: (params) => parseFloat(params.value).toFixed(2)
    },
    {
      field: 'remark',
      headerName: 'Remark',
      width: 200,
      editable: false,
      sortable: false,
      renderCell: RenderLongText
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      editable: false,
      sortable: false,
      renderCell: RenderStatus
    },
    {
      field: 'approver',
      headerName: 'Approver',
      width: 100,
      sortable: false,
      editable: false,
      valueGetter: (params) => params.value ? appContext.options.users.find((u) => u.id === params.value).name : ''
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      getActions: ({ id }) => {
        const payment = payments.find((p) => p.id === id)
        const actions = [
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ fontSize: 15 }} />}
            disabled={!payment.isNew}
            onClick={() => {
              deletePayment(id);
            }}
            label="Delete"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<ReceiptLongOutlinedIcon sx={{ fontSize: 15 }} />}
            disabled={!payment.attachment}
            onClick={async () => {
              const url = await getInvoicePaymentSlip(payment.attachment);
              window.open(url, '_blank', 'toolbar=no,scrollbars=no,resizable=yes,top=500,left=500,width=400,height=400');
            }}
            label="Delete"
            color="inherit"
          />,
        ];
        return actions;
      }
    }
  ];

  const updatePayment = (payment) => {
    const index = payments.findIndex((i) => i.id === payment.id);
    if (index === -1) {
      updatePayments([...payments, payment]);
    } else {
      const newItems = payments.filter((i) => i.id !== selectedPayment.id);
      newItems.splice(index, 0, payment);
      updatePayments(newItems);
    }
  }

  const deletePayment = (id) => {
    updatePayments(payments.filter((i) => i.id !== id));
  }

  return (
    <>
      {selectedPayment && <DialogPayment payment={selectedPayment} updatePayment={updatePayment} close={() => setSelectedPayment(null)} />}
      <Box sx={{
        '& .MuiDataGrid-cell': {
          cursor: 'pointer'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        width: '100%'
      }}>
        <DataGrid
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" paddingTop={10} alignItems="center" justifyContent="center">
                No rows
              </Stack>
            ),
          }}
          disableColumnMenu
          apiRef={apiRef}
          autoHeight={true}
          editMode="row"
          rows={payments}
          columns={columns}
          onRowClick={(e) => {
            const p = payments.find((i) => i.id === e.id);
            if (p.status === 'pending' || p.isNew) {
              setSelectedPayment(p);
            }
          }}
          hideFooter={true}
        />
      </Box>
    </>
  );
}

export default Payments;
