import { httpGet } from '../utils/api';
import configs from '../configs';

export const getInvoices = async (dates, dateColumn, statuses, currencies) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/admin/report/invoices?dates=${dates}&datecolumn=${dateColumn}&statuses=${statuses}&currencies=${currencies}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const getItems = async (dates, dateColumn, items) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/admin/report/items?dates=${dates}&datecolumn=${dateColumn}&items=${items}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const getCars = async (dates, status) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/admin/report/cars?dates=${dates}&status=${status}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};