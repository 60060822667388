import { Navigate } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import { getLoginSession } from './services/auth-service';
import LoginPage from './pages/Login';
import InvoicesPage from './pages/Invoices';
import ItemsPage from './pages/Items';
import UsersPage from './pages/Users';
import AccountPage from './pages/Account';
import MyTasksPage from './pages/MyTasks';
import ViewInvoicePage from './pages/ViewInvoice';
import NotFoundPage from './pages/NotFound';
import DashboardPage from './pages/Dashboard';
import ReportsPage from './pages/Reports';
import ReservationVouchersPage from './pages/ReservationVouchers';
import CarRentalPage from './pages/CarRentals';
import RemindersPage from './pages/Reminders';

const routes = [
  {
    path: '/app',
    element: getLoginSession() ? <MainLayout/> : <Navigate to='/login' />,
    children: [
      { path: 'dashboard', element: <DashboardPage /> },
      { path: 'invoices', element: <InvoicesPage /> },
      { path: 'reservation-vouchers', element: <ReservationVouchersPage /> },
      { path: 'items', element: <ItemsPage /> },
      { path: 'users', element: <UsersPage /> },
      { path: 'account', element: <AccountPage /> },
      { path: 'tasks', element: <MyTasksPage /> },
      { path: 'car-rentals', element: <CarRentalPage /> },
      { path: 'reports', element: <ReportsPage /> },
      { path: 'reminders', element: <RemindersPage />}
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/login', element: <LoginPage /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '/invoices/:id', element: <ViewInvoicePage /> },
      { path: '/404', element: <NotFoundPage />},
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];


const getRoutes = () => {
  return routes;
}
export default getRoutes;
