import * as React from 'react';
import {
  Button, TextField, DialogTitle, DialogContent, DialogActions, Dialog, Select, MenuItem, InputLabel, FormControl,
  Box, Card, CardContent, Typography, FormGroup
} from '@mui/material';
import AppContext from '../../context';
import { decimalsCount } from '../../utils/etc';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { getLoginSession } from '../../services/auth-service';
import { PaymentTypes } from '../../configs/system';

const Payment = ({ payment, close, updatePayment }) => {
  const appContext = React.useContext(AppContext);
  const [error, setError] = React.useState({});
  const [state, setState] = React.useState(payment);

  const validateInvoicePayment = () => {
    const maybeError = {
      ...error,
      payment: state.payment === '',
      type: state.type === '',
      amount: state.amount === '',
    };
    setError(maybeError);
  };

  const handleInputChange = (e) => {
    let { value } = e.target;
    let approver = null;
    switch (e.target.name) {
      case 'amount':
        value = value.replace('e', '');
        if (decimalsCount(value) > 2) {
          value = parseFloat(value).toFixed(2);
        }
        break;
      case 'status':
        if (e.target.value === 'approved' || e.target.value === 'rejected') {
          approver = getLoginSession().sub;
        }
        break;
      default: break;
    }
    const newState = approver ? { ...state, [e.target.name]: value, approver } : { ...state, [e.target.name]: value};
    setState(newState);
  };

  const save = async () => {
    validateInvoicePayment();
    if ((state.item === '')
      || (state.payment === '') || (state.type === '') || (state.amount === '')) {
      appContext.showMessage('warning', 'Please fill in all the required fields.');
      return;
    }
    updatePayment(state);
    close(false);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={close}>
      <DialogTitle>
        <Typography variant="h4">
          Invoice Payment
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Card variant="outlined" sx={{ borderColor: '#B5B5B2', borderWidth: 1 }}>
          <CardContent>
            <FormGroup>
              {state.status !== 'approved' &&
                <FormControl fullWidth >
                  <InputLabel id="status-select-label">Status</InputLabel>
                  <Select
                    value={state.status}
                    labelId="status-select-label"
                    id="status"
                    name="status"
                    label="status"
                    size="small"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="approved">Approved</MenuItem>
                    <MenuItem value="rejected">Rejected</MenuItem>
                  </Select>
                  {error.status && (
                    <span style={{
                      color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                    }}
                    >
                      Invalid input.
                    </span>
                  )}
                </FormControl>
              }
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel id="payment-select-label">Payment</InputLabel>
                <Select
                  value={state.payment}
                  labelId="payment-select-label"
                  id="payment"
                  name="payment"
                  label="Payment"
                  size="small"
                  onChange={handleInputChange}
                >
                  <MenuItem value="balance">Balance</MenuItem>
                  <MenuItem value="deposit">Deposit</MenuItem>
                  <MenuItem value="full">Full</MenuItem>
                </Select>
                {error.payment && (
                  <span style={{
                    color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                  }}
                  >
                    Invalid input.
                  </span>
                )}

              </FormControl>

              <Box sx={{ paddingTop: 1, paddingBottom: 3 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel sx={{ paddingLeft: 1, marginTop: 0, fontSize: 12 }} size="small">Transaction Date</InputLabel>
                  <br />
                  <MobileDatePicker
                    size="small"
                    renderInput={(params) => <TextField label="Transaction Date" fullWidth size="small" {...params} />}
                    onChange={(newValue) => {
                      handleInputChange({ target: { name: 'date', value: newValue } });
                    }}
                    value={state.date}
                    inputFormat="YYYY-MM-DD"
                  />
                </LocalizationProvider>
              </Box>
              <FormControl fullWidth>
                <InputLabel id="type-select-label">Type</InputLabel>
                <Select
                  value={state.type}
                  labelId="type-select-label"
                  id="type"
                  name="type"
                  label="type"
                  size="small"
                  onChange={handleInputChange}
                >
                  {PaymentTypes.map((t) =><MenuItem key={t.id} value={t.id}>{t.name}</MenuItem> )}
                </Select>
                {error.type && (
                  <span style={{
                    color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                  }}
                  >
                    Invalid input.
                  </span>
                )}
                <TextField
                  sx={{ marginTop: 2 }}
                  error={error.amount}
                  helperText={error.amount ? 'Invalid input.' : ''}
                  value={state.amount}
                  fullWidth
                  margin="dense"
                  id="amount"
                  name="amount"
                  label="amount (RM)"
                  size="small"
                  type="number"
                  variant="outlined"
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ marginTop: 2 }}
                  value={state.remark}
                  fullWidth
                  id="remark"
                  name="remark"
                  label="remark"
                  multiline
                  rows={2}
                  inputProps={{ maxLength: 360 }}
                  onChange={handleInputChange}
                />
              </FormControl>
            </FormGroup>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <>
          <Button variant="contained" onClick={save}>Save</Button>
          <Button onClick={() => close(false)}>Close</Button>
        </>
      </DialogActions>
    </Dialog >
  );
};

export default Payment;
