import { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import {
  Card, CardContent, Box, Container, Tabs, Tab
} from '@mui/material';
import Invoices from '../components/reports/Invoices';
import Items from '../components/reports/Items';
import Cars from '../components/reports/Cars';
import AppContext from '../context';
import { getOptions } from '../services/invoice-service';

const Reports = () => {
  const appContext = useContext(AppContext);
  const [state, setState] = useState({
    tab: 'invoice'
  });
  const changeTab = (event, value) => {
    setState({ ...state, tab: value });
  };

  const loadOptions = async () => {
    try {
      const options = await getOptions();
      appContext.setOptions(options);
    } catch {
      appContext.showMessage('Error', 'Some error had occured in getting options. Please try again.');
    }
  }

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <>
      <Helmet>
        <title>Invoices | XQ</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Card>
            <CardContent>
              <Tabs
                value={state.tab}
                textColor="primary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
                onChange={changeTab}
              >
                <Tab label="Invoices" value="invoice" />
                <Tab label="Items" value="item" />
                <Tab label="Cars Pick Up / Return" value="cars" />
              </Tabs>
              {state.tab === 'invoice' && <Invoices />}
              {state.tab === 'item' && <Items />}
              {state.tab === 'cars' && <Cars />}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Reports;
