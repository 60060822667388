import * as React from 'react';
import { DataGrid, GridToolbarContainer, GridActionsCellItem, useGridApiRef, GridNoRowsOverlay } from '@mui/x-data-grid';
import { Button, Popover, Chip, Box, Typography, Stack } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import DialogInvoiceItem from './Item';
import { getLoginSession } from '../../services/auth-service';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import dayjs from 'dayjs';

const RenderLongText = (param) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const isLongText = param.value.length > 20;
  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {isLongText ? param.value.substring(0, 20) + '...' : param.value}
      </Typography>
      {isLongText &&
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1, overflowX: 'auto', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{param.value}</Typography>
        </Popover>
      }
    </div>
  );
}
const RenderStatus = (param) => {
  return param.value ? <Chip size="small" label="booked" color="success" variant="outlined" /> : <></>;
}
const Items = ({ items, updateItems }) => {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const apiRef = useGridApiRef();
  const GridToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button color="primary" onClick={() => setSelectedItem({
          id: uuidv4(),
          booked: false,
          itemId: '',
          desc: '',
          billNo: '',
          cost: '',
          price: '',
          quantity: 1,
          deleted: false,
          isNew: true,
        })}>
          Add Item
        </Button>
      </GridToolbarContainer >
    );
  }

  const columns = [
    {
      field: 'booked',
      headerName: 'Status',
      width: 80,
      editable: false,
      sortable: false,
      renderCell: RenderStatus
    },
    {
      field: 'itemId',
      headerName: 'Item',
      width: 100,
      sortable: false,
      editable: false,
    },
    {
      field: 'desc',
      headerName: 'Description',
      width: 200,
      editable: false,
      sortable: false,
      renderCell: RenderLongText
    },
    {
      field: 'billNo',
      headerName: 'Bill No.',
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      field: 'dueDate',
      headerName: 'RV Due',
      width: 100,
      editable: false,
      sortable: false,
      valueGetter: ({ value }) => value ? dayjs(value).format('YYYY-MM-DD') : null,
    },
    {
      field: 'cost',
      hide: getLoginSession().role !== 1,
      headerName: 'Cost',
      type: 'number',
      editable: false,
      sortable: false,
      valueGetter: ({ value }) => parseFloat(value).toFixed(2),
    },
    {
      field: 'price',
      headerName: 'Price',
      type: 'number',
      editable: false,
      sortable: false,
      valueGetter: ({ value }) => parseFloat(value).toFixed(2),
    },
    {
      field: 'quantity',
      headerName: 'Qty',
      type: 'number',
      width: 80,
      editable: false,
      sortable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      editable: false,
      sortable: false,
      valueGetter: (params) => {
        const item = items.find((i) => i.id === params.id);
        return parseFloat(item.price * item.quantity).toFixed(2);
      }
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      headerName: 'Actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<ArrowUpwardIcon sx={{ fontSize: 15 }} />}
            onClick={() => {
              moveUp(id);
            }}
            label="Move Up"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<ArrowDownwardIcon sx={{ fontSize: 15 }} />}
            onClick={() => {
              moveDown(id);
            }}
            label="Move Down"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ fontSize: 15 }} />}
            onClick={() => {
              deleteItem(id);
            }}
            label="Delete"
            color="inherit"
          />
        ];
      }
    },
  ];

  const updateItem = (item) => {
    const index = items.findIndex((i) => i.id === item.id);
    if (index === -1) {
      updateItems([...items, item]);
    } else {
      const newItems = items.filter((i) => i.id !== selectedItem.id);
      newItems.splice(index, 0, item);
      updateItems(newItems);
    }
  }

  const deleteItem = (id) => {
    if (
      !window.confirm('Reservation voucher(s) associated with this item will be deleted. Are you sure you want to remove this item?')) {
      return;
    }
    const deletedItem = items.find((i) => i.id === id);
    const index = items.findIndex((i) => i.id === id);
    const newItems = items.filter((i) => i.id !== id);
    newItems.splice(index, 0, { ...deletedItem, deleted: true });
    updateItems(() => newItems);
  }

  const moveUp = (id) => {
    const index = items.findIndex((i) => i.id === id);
    const moveItem = items.find((i) => i.id === id);
    const newItems = items.filter((i) => i.id !== id);
    newItems.splice(index - 1, 0, { ...moveItem });
    updateItems(() => newItems);
  }

  const moveDown = (id) => {
    const index = items.findIndex((i) => i.id === id);
    const moveItem = items.find((i) => i.id === id);
    const newItems = items.filter((i) => i.id !== id);
    newItems.splice(index + 1, 0, { ...moveItem });
    updateItems(() => newItems);
  }

  return (
    <>
      {selectedItem && <DialogInvoiceItem item={selectedItem} updateItem={updateItem} close={() => setSelectedItem(null)} />}
      <Box sx={{
        '& .MuiDataGrid-cell': {
          cursor: 'pointer'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        width: '100%'
      }}>
        <DataGrid
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" paddingTop={10} alignItems="center" justifyContent="center">
                No rows
              </Stack>
            ),
          }}
          disableColumnMenu
          autoHeight={true}
          apiRef={apiRef}
          editMode="row"
          rows={items.filter((i) => !i.deleted)}
          columns={columns}
          onRowClick={(e) => setSelectedItem(items.find((i) => i.id === e.id))}
          hideFooter={true}
        />
      </Box>
    </>
  );
}

export default Items;
