import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar, Toolbar, Box, Typography, Backdrop, CircularProgress, Snackbar, Alert, Hidden, IconButton
} from '@mui/material';
import { Menu as MenuIcon } from 'react-feather';
import AppContext from '../context';
import Sidebar from './Sidebar';
import { getLoginSession } from '../services/auth-service';

const MainLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const MainLayoutWrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64
});

const MainLayoutWithSidebarWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  })
);

const MainLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const MainLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const MainLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false });
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  const isLogin = getLoginSession();

  const appContext = {
    showMessage: (severity, message) => {
      setSnackbar({ open: true, severity, message });
    },
    setLoading,
    options,
    setOptions
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  }

  const LayoutWrapper = isLogin ? MainLayoutWithSidebarWrapper : MainLayoutWrapper;

  return (
    <MainLayoutRoot>
      <AppContext.Provider value={appContext}>
        <AppBar
          elevation={0}
        >
          <Toolbar sx={{ height: 64 }}>
            {isLogin && <Hidden lgUp>
              <IconButton color="inherit" onClick={() => setMobileNavOpen(true)} size="large">
                <MenuIcon />
              </IconButton>
            </Hidden>
            }
            <RouterLink to="/">
              <Box sx={{ display: 'flex' }}>
                <img
                  alt="Logo"
                  src="/static/images/logo.png"
                  height={50}
                />
                <div style={{ padding: 15 }}>
                  <Typography
                    color="white"
                    variant="h4"
                  >
                    小强兰卡威旅程 Langkawi Tour
                  </Typography>
                </div>
              </Box>
            </RouterLink>
          </Toolbar>
        </AppBar>
        {isLogin &&
          <Sidebar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
        }

        <LayoutWrapper>
          <MainLayoutContainer>
            <MainLayoutContent>
              <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={closeSnackbar} open={snackbar.open} autoHideDuration={5000}>
                <Alert onClose={closeSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                  {snackbar.message}
                </Alert>
              </Snackbar>
              <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 999999 }}
                open={loading}
              >
                <CircularProgress size={60} color="primary" />
              </Backdrop>
              <Outlet />
            </MainLayoutContent>
          </MainLayoutContainer>
        </LayoutWrapper>
      </AppContext.Provider>
    </MainLayoutRoot>
  );
}

export default MainLayout;
