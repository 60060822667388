import React from "react";
import {
  Button, ButtonGroup, Box, InputLabel
} from '@mui/material';

const NumberInput = ({ title, value, increase, decrease }) => {
  return (
    <Box sx={{ marginRight: 5 }}>
      <InputLabel sx={{ marginBottom: 2, marginLeft:1,  fontSize: 12}} size="small">{title}</InputLabel>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button color="inherit" onClick={decrease}>-</Button>
        <Button color="inherit" onClick={increase}>{value}</Button>
        <Button color="inherit" onClick={increase}>+</Button>
      </ButtonGroup>
    </Box>
  );
}
export default NumberInput;