import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Card, CardContent,
  Grid,
  Chip,
  Typography,
} from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import { capitalizeText } from '../../utils/etc';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
dayjs.extend(utc);
const ReturnList = ({ data, returnCar }) => {

  const renderList = (i) => {

    const items = data.filter(r => dayjs(r.returnTime).format('YYYY-MM-DD') === dayjs().add(1, 'day').subtract(i, 'd').format('YYYY-MM-DD'));
    if (items.length === 0) {
      return <span style={{ paddingLeft: 10 }}>No booking found.</span>
    }
    return items.map(r => (
      <Grid item lg={4} md={6} xs={12}>
        <Card sx={{ cursor: 'pointer', backgroundColor: '#F2F2F2' }} onClick={() => returnCar(r.id)}>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              <span style={{ verticalAlign: 'super' }}>{r.id} </span> {r.status === 'returned' && <CheckOutlinedIcon color='success' size="small" />}
              { r.returnedBy && <Chip sx={{ verticalAlign: 'baseline'}} icon={<FaceIcon />} label={r.returnedBy} />}<br /><br />
              {r['Invoice.name']}<br />
              <a onClick="#" href={`tel:${r['Invoice.contact']}`}>{r['Invoice.contact']}</a> <br />
              {r.car} <br />
              {dayjs(r.collectTime).format('YYYY-MM-DD HH:mm')} {capitalizeText(r.collectLocation)} <br />
              {dayjs(r.returnTime).format('YYYY-MM-DD HH:mm')} {capitalizeText(r.returnLocation)}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ));
  }


  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6, 7].map(i => (
        <Card key={i} sx={{ marginTop: 3 }}>
          <CardContent>
            <h3>{dayjs().add(1, 'day').subtract(i, 'd').format('YYYY-MM-DD')}</h3>
            <Grid container spacing={1}>
              {renderList(i)}
            </Grid>
          </CardContent>
        </Card>
      ))
      }
    </>
  );
};

export default ReturnList;
