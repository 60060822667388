import * as React from 'react';
import {
  Button, TextField, DialogTitle, DialogContent, Dialog, Select, MenuItem, InputLabel, FormControl,
  Grid, Box, Card, CardContent, Typography, AppBar, Toolbar
} from '@mui/material';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import dayjs from 'dayjs';
import {
  dateTimeFormat
} from '../../utils/etc';
import NumberInput from '../NumberInput';
import AppContext from '../../context';
import { capitalizeText } from '../../utils/etc';
import { getCarRental_external, updateCarRental_external } from '../../services/car-service';
const Invoice = ({ id, close, mode }) => {
  const newState = {
    id: 'New',
    car: '',
    agreementNo: '',
    deposit: '0',
    depositPaymentType: '',
    collectTime: dayjs().startOf('day').format(dateTimeFormat),
    returnTime: dayjs().startOf('day').add(1, 'day').format(dateTimeFormat),
    collectLocation: '',
    returnLocation: '',
    remark: '',
    status: 'new',
    realCollectTime: null,
    realCollectLocation: '',
    fuel: '1/4',
    realReturnTime: null,
    realReturnLocation: '',
    damageCharge: '',
    summonCharge: '',
    petrolCharge: '',
    extraHourCharge: '',
    chargesPaymentType: '',
    isNew: true,
  };
  const appContext = React.useContext(AppContext);
  const [error, setError] = React.useState({});
  const [state, setState] = React.useState(newState);

  const loadCarRental = async () => {
    try {
      appContext.setLoading(true);
      const data = await getCarRental_external(id);
      setState({ ...data, status: 'collected' });
    } catch (e) {
      console.error(e);
      appContext.showMessage('error', `Error in procesing this request: ${e}`);
    } finally {
      appContext.setLoading(false);
    }
  };

  React.useState(() => {
    loadCarRental();
  }, []);

  const validateInvoice = () => {
    let maybeError = {
      ...error,
      car: !state.car,
      realCollectTime: state.realCollectTime === null,
      realCollectLocation: state.realCollectLocation === null,
      realReturnTime: state.realReturnTime === null || state.realReturnLocation < state.realCollectTime,
      realReturnLocation: state.realReturnLocation === null,
      deposit: state.deposit === null || state.deposit === '',
      depositPaymentType: state.depositPaymentType === null || state.depositPaymentType === '',
      agreementNo: state.agreementNo === null || state.agreementNo === '',
      fuel: state.fuel === null || state.fuel === ''
    };
    setError(maybeError);
  };

  const handleInputChange = (e) => {
    const newState = { ...state, [e.target.name]: e.target.value }
    setState(newState);
  };

  const handleFuelChange = (e) => {
    const newState = { ...state };
    const fuel = parseInt(state.fuel.split('/')[0]);
    const maxFuel = parseInt(state.fuel.split('/')[1]);

    switch (e.target.name) {
      case '+':
        if (fuel < maxFuel ) {
          newState.fuel = `${fuel + 1}/${maxFuel}`;
          setState(newState);
        }
        break;
      case '-':
        if (fuel > 0) {
          newState.fuel = `${fuel - 1}/${maxFuel}`;
          setState(newState);
        }
        break;
      case 'maxFuel':
        newState.fuel = `${e.target.value}/${e.target.value}`;
        setState(newState);
        break;
      default:
    }
  };

  const save = async () => {
    validateInvoice();
    if (!state.car
      || !state.realCollectTime
      || !state.realReturnTime
      || (state.deposit === null || state.deposit === '')
      || (state.depositPaymentType === null || state.depositPaymentType === '')
      || (state.agreementNo === null || state.agreementNo === '')
      || (state.realCollectLocation === null)
      || (state.realReturnLocation === null)
      || (state.fuel === null || state.fuel === '')) {
      appContext.showMessage('warning', 'Please fill in all the required fields.');
      return;
    }

    try {
      appContext.setLoading(true);
      await updateCarRental_external(id, state);
      appContext.showMessage('success', `Car rental ${id} collected successfully.`);
      close(true);
    } catch (e) {
      console.error(e);
      appContext.showMessage('error', `Car rental collection failed: ${e.response.data}.`);
    } finally {
      appContext.setLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" open onClose={close}  >
      <DialogTitle>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h4" component="div">
              {id}
            </Typography>
            <Button onClick={save} color="inherit">
              save
            </Button> |
            <Button onClick={() => close(true)} color="inherit">
              Close
            </Button>
          </Toolbar>
        </AppBar>

      </DialogTitle>
      <DialogContent style={{ height: '80vh', margin: 15 }}>
        <Card variant="outlined" sx={{
          borderColor: '#B5B5B2', borderWidth: 1
        }}>
          <CardContent>
            <table>
              <tr>
                <td><b>Invoice Id</b></td>
                <td>:</td>
                <td>{state.id}</td>
              </tr>
              <tr>
                <td><b>Name</b></td>
                <td>:</td>
                <td>{state['Invoice.name']}</td>
              </tr>
              <tr>
                <td><b>Contact</b></td>
                <td>:</td>
                <td>{state['Invoice.contact']}</td>
              </tr>
              <tr>
                <td><b>Pick Up</b></td>
                <td>:</td>
                <td>{dayjs(state.collectTime).format('YYYY-MM-DD HH:mm')} {capitalizeText(state.collectLocation)}</td>
              </tr>
              <tr>
                <td><b>Return</b></td>
                <td>:</td>
                <td>{dayjs(state.returnTime).format('YYYY-MM-DD HH:mm')} {capitalizeText(state.returnLocation)}</td>
              </tr>
            </table>
          </CardContent>
        </Card>

        <Card
          variant="outlined"
          sx={{
            marginTop: 2,
            borderColor: '#B5B5B2', borderWidth: 1
          }}
        >
          <CardContent>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel sx={{ marginTop: -2, paddingLeft: 1, paddingBottom: 2, fontSize: 12 }} size="small">Real Pick Up Time</InputLabel>
                  <MobileDateTimePicker
                    size="small"
                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                    onChange={(newValue) => {
                      handleInputChange({ target: { name: 'realCollectTime', value: newValue } });
                    }}
                    value={state.realCollectTime}
                    inputFormat="YYYY-MM-DD HH:mm"
                  />
                </LocalizationProvider>
                {error.realCollectLocation && (
                  <span style={{
                    color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                  }}
                  >
                    Invalid input.
                  </span>
                )}
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel sx={{ marginTop: -2, paddingLeft: 1, paddingBottom: 2, fontSize: 12 }} size="small">Real Return Time</InputLabel>
                  <MobileDateTimePicker
                    size="small"
                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                    onChange={(newValue) => {
                      handleInputChange({ target: { name: 'realReturnTime', value: newValue } });
                    }}
                    value={state.realReturnTime}
                    inputFormat="YYYY-MM-DD HH:mm"
                  />
                </LocalizationProvider>
                {error.realReturnTime && <span style={{ color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15 }}>Real return time can not be blank or earlier than real pick up time.</span>}
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl sx={{ marginTop: 2 }} fullWidth>
                  <InputLabel id="pickup-real-location-select-label">Real Pick Up Location</InputLabel>
                  <Select
                    value={state.realCollectLocation}
                    labelId="pickup-real-location-select-label"
                    id="realCollectLocation"
                    name="realCollectLocation"
                    label="Real Pick Up Location"
                    size="small"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="airport">Airport</MenuItem>
                    <MenuItem value="hotel">Hotel</MenuItem>
                    <MenuItem value="jetty">Jetty</MenuItem>
                    <MenuItem value="roro">Roro</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                  {error.realCollectLocation && (
                    <span style={{
                      color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                    }}
                    >
                      Invalid input.
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl sx={{ marginTop: 2 }} fullWidth>
                  <InputLabel id="return-real-location-select-label">Real return Location</InputLabel>
                  <Select
                    value={state.realReturnLocation}
                    labelId="return-real-location-select-label"
                    id="realReturnLocation"
                    name="realReturnLocation"
                    label="Real Return Location"
                    size="small"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="airport">Airport</MenuItem>
                    <MenuItem value="hotel">Hotel</MenuItem>
                    <MenuItem value="jetty">Jetty</MenuItem>
                    <MenuItem value="roro">Roro</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                  {error.realReturnLocation && (
                    <span style={{
                      color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                    }}
                    >
                      Invalid input.
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12} sx={{ paddingTop: 2 }}>
                <TextField
                  value={state.car}
                  error={error.car}
                  helperText={error.car ? 'Invalid input.' : ''}
                  fullWidth
                  margin="dense"
                  id="car"
                  name="car"
                  label="Car"
                  size="small"
                  type="text"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12} sx={{ paddingTop: 2 }}>
                <TextField
                  value={state.agreementNo}
                  error={error.agreementNo}
                  helperText={error.agreementNo ? 'Invalid input.' : ''}
                  fullWidth
                  margin="dense"
                  id="agreementNo"
                  name="agreementNo"
                  label="Agreement No."
                  size="small"
                  type="text"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  error={error.deposit}
                  helperText={error.deposit ? 'Invalid input.' : ''}
                  value={state.deposit ? state.deposit : ''}
                  fullWidth
                  margin="dense"
                  id="deposit"
                  name="deposit"
                  label="Deposit (RM)"
                  size="small"
                  type="number"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl sx={{ marginTop: 1 }} fullWidth>
                  <InputLabel id="deposit-payment-type-select-label">Deposit Payment Type</InputLabel>
                  <Select
                    value={state.depositPaymentType}
                    labelId="deposit-payment-type-select-label"
                    id="depositPaymentType"
                    name="depositPaymentType"
                    label="Deposit Payment Type"
                    size="small"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="mbb">MBB</MenuItem>
                    <MenuItem value="cash">Cash</MenuItem>
                    <MenuItem value="creditcard">Credit Card</MenuItem>
                  </Select>
                  {error.depositPaymentType && <span style={{ color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15 }}>Invalid input.</span>}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Box sx={{ display: 'flex' }}>
                  <NumberInput title="Fuel"
                    value={state.fuel.split('/')[0]}
                    increase={() => { handleFuelChange({ target: { name: '+' } }) }}
                    decrease={() => { handleFuelChange({ target: { name: '-' } }) }} />

                  <Box sx={{ marginLeft: -4, marginTop: 5, fontSize: 20, paddingRight: 1 }} > / </Box>
                  <FormControl sx={{ marginTop: 4 }}>

                    <Select
                      sx={{ height: 32 }}
                      value={state.fuel.split('/')[1]}
                      id="maxFuel"
                      name="maxFuel"
                      size="small"
                      onChange={handleFuelChange}
                    >
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="8">8</MenuItem>
                      <MenuItem value="16">16</MenuItem>
                    </Select>
                  </FormControl>

                </Box>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  sx={{ marginTop: 2 }}
                  value={state.remark}
                  fullWidth
                  id="remark"
                  name="remark"
                  label="Remark"
                  multiline
                  rows={5}
                  inputProps={{ maxLength: 5000 }}
                  helperText="Max 5000 characters"
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog >
  );
};

export default Invoice;
